import React, {Component, Fragment} from "react";
import HeaderComponent from '../../reuse/header/header.component';
import FooterComponent from "../../reuse/footer/footer.component";
import LoaderComponent from "../../reuse/loader/Loader.component";
import BlogItemComponent from "./blog-item/blog-item.component";
import css from './blog.module.scss';

class BlogComponent extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

        this.state = {
            showLoader: true, blog: undefined
        }

        document.querySelector('#root').classList.add('header-fixed');

        this.getBlogList();
    }

    getBlogList() {
        fetch('https://panel.stairwaysoft.com/api/wp/v2/blog')
            .then(response => response.json())
            .then(data => {
                console.log(data, 'dqwdq222dq');
                this.setState({
                    showLoader: false, blog: data
                })
            })
            .catch(error => {
                console.error(error)
            })
    }

    render() {
        return (
            <Fragment>
                <HeaderComponent/>
                <div className="container">
                    <LoaderComponent visible={this.state.showLoader}/>
                    <section className={`${css.section_blog}`}>
                        <h2 className={`${css.section_title}`}>Blog</h2>
                        {this.state.blog && <BlogItemComponent blog={this.state.blog}/>}
                    </section>
                </div>
                <FooterComponent/>
            </Fragment>
        )
    }
}

export default BlogComponent;
