import React, {Component, Fragment} from "react";
import HeaderComponent from "../../reuse/header/header.component";
import LoaderComponent from "../../reuse/loader/Loader.component";
import FooterComponent from "../../reuse/footer/footer.component";
import ConnectComponent from "../../reuse/connect/connect.component";
import AboutHomeComponent from "../mainPage/about/about.component";
import VacancyListComponent from "./list/vacancy-list.component";
import {withRouter} from "react-router-dom";
import FirstInfoComponent from "./first-info/first-info.component";
import CvFormModalComponent from "./cv-form-modal/cv-form-modal.component";
import NotFound from "../NotFound";
import CvFormVacancyComponent from "../../reuse/cv-form/cv-form.vacancy.component";
import Modal from 'react-awesome-modal';

class VacancyComponent extends Component {
  constructor(prop) {
    super(prop);
    window.scrollTo(0, 0);
    this.state = {
      visible: false,
      modalThankYouVisible: false,
      reCapcheStateModal: false,
      pathFile: '',
      name: '',
      email: '',
      phone: '',
      message: '',
      formValid: false,
      showSuccessMsg: false,
      showErrorMsg: false,
      pathFileModal: '',
      nameModal: '',
      emailModal: '',
      phoneModal: '',
      messageModal: '',
      formValidModal: false,
      showSuccessMsgModal: false,
      showErrorMsgModal: false,
      careerData: {},
      showLoader: true,
      listResult: [],
      firstInfoResult: {},
      not_found: false,
      loaded: false,
    }

  }

  componentDidMount() {
    const showLoader = {
      showLoader: false,
    }

    fetch(`https://panel.stairwaysoft.com/api/wp/v2/vacancy/?slug=${this.props.match.params.id}`)
      .then(response => response.json())
      .then(data => {
        if (data.ID) {
          this.setState({
            not_found: false,
            loaded: true,
            ...showLoader
          })
        } else {
          this.setState({
            loaded: true,
            not_found: true,
            ...showLoader
          })
        }
        this.setState({
          listResult: data.what_should_i_expect,
          firstInfoResult: {
            title: data.title,
            description: data.description.split('\n').map(_ => _.replace('\r', '<br/>')).join('') || data.description,
            vacancies_filter: data.vacancies_filter,
            first_info_image: data.first_info_image,
          }
        })
      })
      .catch(_ => _);

  }

  openModal = (a) => {
    this.setState({
      visible: true
    })
  }

  closeModal = (a) => {
    this.setState({
      visible: false
    })
  }

  modalThankYouIsVisible = () => {
    if (this.state.visible) this.setState({visible: false});
    this.setState({modalThankYouVisible: true});
  }

  render() {
    if (!this.state.not_found) {
      return (
        <Fragment>

          <Modal visible={this.state.modalThankYouVisible} effect="fadeInLeft"
                 onClickAway={() => this.setState({modalThankYouVisible: false})}>
            <section data-section-modal className="section-bg scroll">
              <input type={"button"} className='close-modal-btn'
                     onClick={() => this.setState({modalThankYouVisible: false})}/>
              <div className="modal-massage__container">
                <span className="modal-massage__title">Thank you for your application</span>
                <span className="modal-massage__sub-title">Our recruiters will contact you soon</span>
                <button className="top-nav__btn btn btn--upper modal-massage__btn"
                        onClick={() => this.setState({modalThankYouVisible: false})}>close
                </button>
              </div>
            </section>
          </Modal>

          <LoaderComponent visible={this.state.showLoader}/>
          {this.state.loaded &&
            <Fragment>
              <HeaderComponent/>
              {this.state.visible &&
                <CvFormModalComponent closeModal={this.closeModal}
                                      modalThankYouIsVisible={this.modalThankYouIsVisible}/>
              }
              {
                Object.keys(this.state.firstInfoResult).length &&
                <FirstInfoComponent openModal={this.openModal} visibleModal={this.state.visible}
                                    info={this.state.firstInfoResult}/>
              }

              <VacancyListComponent openModal={this.openModal} fetch={this.state.listResult}/>

              <AboutHomeComponent updateData={this.props.updateData}/>

              <ConnectComponent/>
              <CvFormVacancyComponent modalThankYouIsVisible={this.modalThankYouIsVisible}/>

            </Fragment>
          }
          <FooterComponent/>
        </Fragment>
      )
    } else {
      return (
        <NotFound/>
      )

    }

  }

}

export default withRouter(VacancyComponent)
