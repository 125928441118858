import React, {Component, Fragment} from 'react';

class FirsScreenComponent extends Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <Fragment>
        <section className='career-img-section'
                 style={{background: 'url("/img/road-map/road_map_bg.png") no-repeat center'}}>
          <div className='career-img-section__text-block'>
            <h1 className='career-img-section__title'>
              Dedicated Team Setup Road Map
            </h1>
            <div className='career-img-section__description'>
              <p className='career-img-section__paragraph'>
                During all the process recruiter assists communication between client and candidate. This involves:
                organization of tests, interviews with client and candidate, coordination
                between client and candidate when making a final offer.
              </p>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default FirsScreenComponent;
