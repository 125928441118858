import React, {Component, Fragment} from "react";
import css from './first-info.component.module.scss';

class FirstInfoComponent extends Component {
	constructor(prop) {
		super(prop);
		this.state = {
			result: prop.info,
			isMobile: false
		}
	}

	componentDidMount() {
		this.getWindowSize();
		window.addEventListener('resize', () => {
			this.getWindowSize();
		});

	}

	getWindowSize = () => {
		if (window.innerWidth < 1025) {
			this.putStateIsMobile(true);
		} else {
			this.putStateIsMobile(false);
		}
	}

	putStateIsMobile = (value) => {
		this.setState({
			isMobile: value
		})
	}

	render() {

		return (
			<Fragment>
				<section className={css.first_inf_section}>
					<div className={`${css.first_info_container} container`}>
						{
							this.state.result.first_info_image && this.state.isMobile &&

							<img
								src={this.state.result.first_info_image}
								alt=""
								className={css.first_info_image_mobile}
							/>

						}

						<div className={css.first_info_left}>

							<h2
								data-main-vacancy-title={this.state.result.title}
								className={css.first_info_title}
							>
								{this.state.result.title}
							</h2>

							<span dangerouslySetInnerHTML={{__html: this.state.result.description}}
										className={css.first_info_description}></span>
						</div>
						<div className={css.first_info_right}>
							{
								this.state.result.first_info_image && !this.state.isMobile &&

								<img
									src={this.state.result.first_info_image}
									alt=""
									className={css.first_info_image_desktop}
								/>

							}

							<table className={css.short_info}>
								<tbody>
								{this.state.result.vacancies_filter.location.length &&
									<tr className={css.short_info_row}>
										<td className={css.short_info_row_title}>Location:</td>
										<td
											className={css.short_info_row_value}>{this.state.result.vacancies_filter.location.map(_ => _.name).join(', ')}</td>
									</tr>
								}
								{this.state.result.vacancies_filter.work_type.length &&
									<tr className={css.short_info_row}>
										<td className={css.short_info_row_title}>Type of employment:</td>
										<td
											className={css.short_info_row_value}>{this.state.result.vacancies_filter.work_type.map(_ => _.name).join(', ')}</td>
									</tr>
								}
								{this.state.result.vacancies_filter.project.length &&
									<tr className={css.short_info_row}>
										<td className={css.short_info_row_title}>Project:</td>
										<td className={css.short_info_row_value}>
											{this.state.result.vacancies_filter.project.map(_ => _.name).join(', ')}
										</td>
									</tr>
								}
								{this.state.result.vacancies_filter.specialization.length &&
									<tr className={css.short_info_row}>
										<td className={css.short_info_row_title}>Specialization:</td>
										<td
											className={css.short_info_row_value}>{this.state.result.vacancies_filter.specialization.map(_ => _.name).join(', ')}</td>
									</tr>
								}
								</tbody>

							</table>

						</div>
					</div>
					<div className={`container ${css.short_info__button_container}`}>
            <span className={`${css.blue_button} ${css.margin_r}`}
									onClick={this.props.openModal}
						>
              send resume
            </span>
					</div>

				</section>
			</Fragment>
		)
	}

}

export default FirstInfoComponent;