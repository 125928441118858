import React, {Component, Fragment} from "react";
import css from "../price-calculator.module.scss";
import GetPriceComponent from "../../../sales/get-price/get-price.component";

const salary = {
  Junior: 1000, Middle: 2000, Senior: 3000
}

const cost = {
  value: 9200,
}

class ResultTableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteItemId: null,
    }

    this.setDeleteItemId = this.setDeleteItemId.bind(this);
  }

  setDeleteItemId(_) {
    if (_ !== undefined) {
      _.stopPropagation();
      let filterData = this.props.list.filter(item => item.id !== _.target.id);


      console.log(filterData)
      this.props.setStateList(filterData)

      this.setState({
        deleteItemId: _.target.id
      })
    }
  }

  render() {
    return (
      <Fragment>

        {
          this.props.list.length > 0 ? (
            <>
              <div className={`${css.table__container__wrapper}`}>
                <div className="container">
                  <div className={`${css.table__container}`}>
                    <h2 className={`${css.table__title}`}>Monthly saving:</h2>
                    <table className={`${css.table}`}>
                      <thead className={`${css.table__head}`}>
                      <tr>
                        <th className={`${css.table_head}`}>Position:</th>
                        <th className={`${css.table_head}`}>Number:</th>
                        <th className={`${css.table_head}`}>Average cost in EE*</th>
                        <th className={`${css.table_head}`}>Cost in California</th>
                        <th className={`${css.table_head}`}>Saving</th>
                        <th className={`${css.table_head__btn}`}></th>
                      </tr>
                      </thead>
                      <tbody>
                      {this.props.list && this.props.list.map(item =>
                        (
                        <tr key={item.id}
                                                                           className={`${css.table__row}`}>
                        <td className={`${css.table__cell}`}>
                          <div className={`${css.table__cell__container}`}>
                            <span className={`${css.table_head__text}`}>Position:</span>
                            <span className={`${css.text__result}`}>{item.Position} {item.Specialization}</span>
                          </div>
                        </td>
                        <td className={`${css.table__cell}`}>
                          <div className={`${css.table__cell__container}`}>
                            <span className={`${css.table_head__text}`}>Number:</span>
                            <span className={`${css.text__result}`}>{item.Number}</span>
                          </div>
                        </td>
                        <td className={`${css.table__cell} ${css.text_bold}`}>
                          <div className={`${css.table__cell__container}`}>
                            <span className={`${css.table_head__text}`}>Average cost in EE*</span>
                            <span className={`${css.text__result__bold}`}>{'$' + item[`specialization_price_${item.Position.toLowerCase()}`] * item.Number}</span>
                          </div>
                        </td>
                        <td className={`${css.table__cell} ${css.text_bold}`}>
                          <div className={`${css.table__cell__container}`}>
                            <span className={`${css.table_head__text}`}>Cost in California</span>
                            <span className={`${css.text__result__bold}`}>{'$' + item[`cost_in_california_${item.Position.toLowerCase()}`] * item.Number}</span>
                          </div>
                        </td>
                        <td className={`${css.table__cell}`}>
                          <div className={`${css.table__cell__container}`}>
                            <span className={`${css.table_head__text}`}>Saving</span>
                            <div>
                              <p className={`${css.text_underline}`}>{'$' + item[`cost_in_california_${item.Position.toLowerCase()}`] * item.Number}</p>
                              <p
                                className={`${css.text__result__bold}`}>{'$' + (item[`cost_in_california_${item.Position.toLowerCase()}`] * item.Number - item[`specialization_price_${item.Position.toLowerCase()}`] * item.Number)}</p>
                            </div>
                          </div>
                        </td>
                        <td className={`${css.table__cell__btn}`}>
                          <button id={item.id} className={`${css.table__btn}`}
                                  onClick={this.setDeleteItemId}>
                          </button>
                        </td>
                      </tr>))}

                      <tr className={`${css.table__row__total}`}>
                        <td className={`${css.text__total}`}>TOTAL SAVE:</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <div className={`${css.table__cell__total}`}>
                            <p className={`${css.text__total__result}`}>
                              {'$' + this.props.list.reduce((accumulator, item) => {
                                return accumulator + (item[`cost_in_california_${item.Position.toLowerCase()}`] * item.Number - item[`specialization_price_${item.Position.toLowerCase()}`] * item.Number)
                              }, 0)
                              }
                            </p>
                            <p className={`${css.text__total__text}`}>per year</p>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <GetPriceComponent/>
                </div>
              </div>

              <div>
                <div className="container">
                  <div className={`${css.contact}`}>
                    <img className={`${css.contact_img}`} src='/img/pricing/business-people-meeting.jpg'/>
                    <div>
                      <p className={`${css.contact_text}`}>*Cost means establishing and maintaining your R&D office from
                        scratch and includes:</p>
                      <ul className={`${css.contact_list}`}>
                        <li className={`${css.contact_list__item}`}>HR services;</li>
                        <li className={`${css.contact_list__item}`}>Developers Salary;</li>
                        <li className={`${css.contact_list__item}`}>Payroll and accounting;</li>
                        <li className={`${css.contact_list__item}`}>Legal & Compliance;</li>
                        <li className={`${css.contact_list__item}`}>Real Estate services</li>
                        <li className={`${css.contact_list__item}`}>Taxes.</li>
                      </ul>

                      <p className={`${css.contact_text} ${css.contact_text__margin}`}>No need in a large up-front
                        investment.
                        Time-to-hire commitment & guarantee.</p>
                      <p className={`${css.contact_text__cursive}`}>Contact us to discover more</p>

                      <button className={`${css.contact_btn}`} onClick={this.handleContactUsBtn}>Contact
                        US
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </>
          ) : ''
        }

      </Fragment>
    )
  }
}

export default ResultTableComponent;