import React, {Component, Fragment} from 'react';
import HeaderComponent from "../../reuse/header/header.component";
import FooterComponent from "../../reuse/footer/footer.component";
import ConnectComponent from "../../reuse/connect/connect.component";
import LoaderComponent from "../../reuse/loader/Loader.component";
import MainPageFormComponent from "../mainPage/form/main-page-form.component";
import CalculatorComponent from "./calculator/calculator.component";
import AboutHomeComponent from "../../reuse/about/about.component";
import ClientsReuse from "../../reuse/clients/clients.component";

import './sales.scss';
import ProvideHomeComponent from "../mainPage/provide/provide.component";
import ServiceComponent from "../mainPage/services/services.component";
import PrincipalsComponent from "./principals/principals.component";
import GetPriceComponent from "./get-price/get-price.component";
import {FirstScreenComponent} from "../../reuse/first-screen/first-screen.component";

const styleOurClients = {
  overflow: 'hidden',
  background: '#f6f8f9',
}

class SalesComponent extends Component {

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = {
      showLoader: true,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: false,
    })
  }



  render() {
    return (
      <Fragment>
        <LoaderComponent visible={this.state.showLoader}/>
        <HeaderComponent/>
        <FirstScreenComponent first_screen_image={'/img/sales/background.jpg'} title={'The most favorable rates for your business'}/>
        <CalculatorComponent/>
        <PrincipalsComponent/>
        <GetPriceComponent/>
        <ServiceComponent updateData={this.props.updateData} showBtn={true}/>
        <ProvideHomeComponent gradientBg={true}/>
        <section className="slider-animate-v1" style={styleOurClients}>
          <ClientsReuse updateData={this.props.updateData}/>
        </section>
        <section className='pt_120'>
          <AboutHomeComponent updateData={this.props.updateData} showBtn={true}/>
        </section>
        <ConnectComponent/>
        <MainPageFormComponent/>
        <FooterComponent/>
      </Fragment>
    )
  }
}

export default SalesComponent;
