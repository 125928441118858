import React, {Component, Fragment} from "react";
import css from './theme-pricing.module.scss';

class ThemePricingComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <section className={`${css.theme}`}>
          <div className="container">
            <div className={`${css.theme_block}`}>
              <h2 className={`${css.theme_title}`}>Dedicated Engineering Team pricing</h2>
              <p className={`${css.theme_text}`}>Compose your team to see how much you can save with your dadicated development team</p>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default ThemePricingComponent;