import React, {Component, Fragment} from "react";

class ConnectComponent extends Component {

  render() {
    return (
      <Fragment>
        <section className="section-bg background-office">
          <div className="contacts-block">
            <div className="contacts-block__item">
              <div className="contacts-block__city">Middle East</div>
              <div className="contacts-block__phone">+972587747472</div>
              <div className="contacts-block__country">Israel | United Arab Emirates</div>
            </div>
            <div className="contacts-block__item">
              <div className="contacts-block__city">Eastern Europe</div>
              <div className="contacts-block__phone">+380947123258</div>
              <div className="contacts-block__country">Ukraine | Bulgaria | Romania</div>
            </div>
            <div className="contacts-block__item">
              <div className="contacts-block__city">Central Asia</div>
              <div className="contacts-block__phone">+77083929937</div>
              <div className="contacts-block__country">Kazakhstan | Uzbekistan | Kyrgyzstan</div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default ConnectComponent;
