import React, {Fragment, Component} from "react";
import './get-price.scss';

export class GetPriceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: 'Please enter a valid email address',
      showError: '',
      validate: false,
      successfulShowMsg: '',
    }

    this.validateEmail = this.validateEmail.bind(this);
    this.submit = this.submit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.email !== this.state.email){
      this.validateEmail();
    }
  }

  regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  handleEmail(e){
    this.setState({
      email: e.target.value
    })
  }

  validateEmail() {
    if (this.state.email === ''){
      this.setState({
        showError: false
      })
    } else {
      if (this.regex.test(this.state.email) === false) {
        this.setState({
          showError: true,
          validate: false
        })
      } else {
        this.setState({
          showError: false,
          validate: true
        })
        return true;
      }
    }
  }

  submit() {
    if (this.state.validate === true) {
      let data = new FormData();
      data.append('your-email', this.state.email)

      fetch('https://panel.stairwaysoft.com/api/contact-form-7/v1/contact-forms/1597/feedback', {
        method: "POST",
        body: data
      })
        .then(response => {
          this.setState({
            ...this.state,
            email: '',
            successfulShowMsg: 'get-price_successful-show',
            validate: false
          })

          setTimeout(() => {
            this.setState({
              ...this.state,
              successfulShowMsg: ''
            })
          }, 5000)

        })
        .catch(error => {
          console.log(error)

          this.setState({
            ...this.state({
              showError: 'get-price_error-message-show'
            })
          })

          setTimeout(() => {
            this.setState({
              ...this.state({
                showError: ''
              })
            })
          }, 5000)
        })
    }
  }


  render() {
    return (
      <Fragment>
        <section className="get-price">
          <div className="container">
            <div className="get-price_content">
              <img className="get-price_img" src='/img/sales/ico_form.svg' alt="iso_form"/>
              <div className="get-price_form">
                <h2 className="get-price_title">Would you like to get the current price for developers?
                  Just leave your *email, we will send it to you immediately
                </h2>
                <input className="get-price_input" placeholder="Your email address" value={this.state.email}
                       onChange={this.handleEmail}/>
                <span className="get-price_error">{this.state.showError === true ? this.state.error : null}</span>
                <span className="get-price_note">*no ads, no spam</span>
                <button className={`get-price_btn ${this.state.email.length === 0 || this.state.validate === false ? 'get-price_btn-disabled' : ''}`} onClick={this.submit} disabled={this.state.email.length === 0}>get price</button>
                <span
                  className={`get-price_successful ${this.state.successfulShowMsg}`}>Thank you for getting in touch!
                </span>
                <span
                className={`get-price_error-message ${this.state.showError}`}>
                  Unfortunately, message wasn't sent. Please try to send it again or contact us by phone.
                </span>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default GetPriceComponent;
