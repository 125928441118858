import React, {Component, Fragment} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {ListAsRadioButton} from "../list-as-radio-button/list-as-radio-button";
import {defaultEnum} from "../vacancies/vacancy-filter/vacancy-filter.component";
import cssListAsRadio from '../../reuse/list-as-radio-button/list-as-radio-button.module.scss'
import cssDef from './cv-form-vacancy-component.module.scss';

const recaptchaRef = React.createRef();

class CvFormVacancyComponent extends Component {

  constructor(prop) {
    super(prop);
    this.state = {
      visible: false,
      pathFile: '',
      name: '',
      email: '',
      message: '',
      formValid: false,
      showSuccessMsg: false,
      showErrorMsg: false,
      vacancy_title: '',
      widthVacanciesList: this.props.widthVacanciesList || false,
      vacancyList: [],
      listVacancyId: ''
    };
  }

  listVacancyIdPut = (value) => {
    this.setState({
      listVacancyId: value
    })
  }

  listVacancyTitlePut = (value) => {
    this.setState({
      vacancy_title: value
    })
  }

  componentDidMount() {
    console.log(this)
    let sectionModal = document.querySelectorAll('[data-section-modal]');
    for (let item of sectionModal) {
      item.closest('div').classList.add('modal-window');
    }

    const dataMainVacancyTitle = document.querySelector(`[data-main-vacancy-title]`);
    if (dataMainVacancyTitle) {
      this.setState({vacancy_title: dataMainVacancyTitle.attributes['data-main-vacancy-title'].value});
    } else {
      this.getVacancySpecialisation();
    }

  }

  getVacancySpecialisation = () => {
    fetch('https://panel.stairwaysoft.com/api/wp/v2/get_vacancy_specialisation')
      .then(response => response.json())
      .then(data => {
        this.setState({
          vacancyList: data.map(item => item.name)
        })
      });
  }

  sendForm = (event) => {
    console.log(this.state, 'this.state')
    console.log('this.state.vacancy_title', this.state.vacancy_title);
    event.preventDefault();
    recaptchaRef.current && recaptchaRef.current.reset();
    // document.querySelector('[form-data="reset-form"]').reset();

    this.setState({
      name: this.state.name.trim(),
      email: this.state.email.trim(),
      message: this.state.message.trim(),
      reCapcheState: false,
    });

    let isEmailValid = false,
      isNameValid = false,
      isMessageValid = false,
      isFileTypeValid = false,
      isSpecialisationValid = false;

    const {name, email, message, pathFile} = this.state;

    const emailRegular = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    const feedbackNameInput = document.getElementById('feedback-name');
    const feedbackNameLabel = feedbackNameInput.closest('label');

    const feedbackEmailInput = document.getElementById('feedback-email');
    const feedbackEmailLabel = feedbackEmailInput.closest('label');

    const feedbackMessageInput = document.getElementById('feedback-message');
    const feedbackMessageLabel = feedbackMessageInput.closest('label');

    const feedbackSpecialisationInput = document.getElementById(`radio_list_${this.state.listVacancyId}`);
    const feedbackSpecialisationLabel = feedbackSpecialisationInput &&
      feedbackSpecialisationInput.closest('label');

    const attachBtnError = document.getElementById('attach-error');

    if (this.state.vacancy_title && this.state.vacancy_title !== defaultEnum.defaultValue) {
      isSpecialisationValid = true;
    }

    if (!name.trim().length) {
      feedbackNameLabel.classList.add('feedback-form__label--error');
      feedbackNameInput.classList.add('feedback-form__input--error');
      isNameValid = false;
    } else {
      feedbackNameLabel.classList.remove('feedback-form__label--error');
      feedbackNameInput.classList.remove('feedback-form__input--error');
      isNameValid = true;
    }

    if (!message.trim().length) {
      feedbackMessageLabel.classList.add('feedback-form__label--error');
      feedbackMessageInput.classList.add('feedback-form__input--error');
      isMessageValid = false;
    } else {
      feedbackMessageLabel.classList.remove('feedback-form__label--error');
      feedbackMessageInput.classList.remove('feedback-form__input--error');
      isMessageValid = true;
    }

    if (!email.trim().length) {
      feedbackEmailLabel.classList.add('feedback-form__label--error');
      feedbackEmailInput.classList.add('feedback-form__input--error');
      isEmailValid = false;
    } else {
      const result = emailRegular.test(String(email).toLowerCase());

      if (!result) {
        feedbackEmailLabel.classList.add('feedback-form__label--error');
        feedbackEmailInput.classList.add('feedback-form__input--error');
        isEmailValid = false;
      } else {
        feedbackEmailLabel.classList.remove('feedback-form__label--error');
        feedbackEmailInput.classList.remove('feedback-form__input--error');
        isEmailValid = true;
      }
    }

    if (feedbackSpecialisationLabel) {
      if (!this.state.vacancy_title.trim().length) {
        feedbackSpecialisationLabel.classList.add(cssListAsRadio.feedback_form__label__error);
        feedbackSpecialisationInput.classList.add('feedback-form__input--error');
        isSpecialisationValid = false;
      } else {
        feedbackSpecialisationLabel.classList.remove(cssListAsRadio.feedback_form__label__error);
        feedbackSpecialisationInput.classList.remove('feedback-form__input--error');
        isSpecialisationValid = true;
      }
    }

    const fileType = pathFile.split('.')[pathFile.split('.').length - 1];

    isFileTypeValid = fileType === 'docx' || fileType === 'doc' || fileType === 'pdf';

    if (!isFileTypeValid) {
      attachBtnError.classList.add('show')
    } else {
      attachBtnError.classList.remove('show')
    }

    if (!this.state.reCapcheState) {
      document.querySelector('.re-capcha').classList.add('error');
    }

    if (!this.state.reCapcheState) {
      document.querySelector('.re-capcha').classList.add('error');
    }
    console.log(isNameValid, isEmailValid, isFileTypeValid, isMessageValid, isSpecialisationValid, this.state.reCapcheState)

    if (isNameValid && isEmailValid && isFileTypeValid && isMessageValid && this.state.reCapcheState) {
      // if (isNameValid && isEmailValid && isFileTypeValid && isMessageValid && isSpecialisationValid && this.state.reCapcheState) {
      let data = new FormData(event.target);
      this.state.reCapcheState = false;
      fetch('https://panel.stairwaysoft.com/api/contact-form-7/v1/contact-forms/516/feedback',
        {
          method: 'POST',
          body: data
        }).then(response => {
        console.log(response, 'cv form response');

        this.setState({
          ...this.state,
          visible: false,
          name: '',
          email: '',
          message: '',
          showSuccessMsg: false,
          pathFile: '',
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            showSuccessMsg: false,
          })
        }, 5000);

        this.props.modalThankYouIsVisible();
      }).catch(error => {
        console.error(error);
        this.setState({
          ...this.state,
          showErrorMsg: true
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            showErrorMsg: false,
          });
        }, 5000)
      })

    }

  };

  render() {

    const state = this.state;

    function onChange(value, e) {
      if (value) {
        state.reCapcheState = true;
        document.querySelector('.re-capcha').classList.remove('error');
      } else {
        state.reCapcheState = false;
        document.querySelector('.re-capcha').classList.add('error');
      }
    }

    return (
      <Fragment>

        <section className="section-bg" id='home-page-form'>
          <div className="container">
            <h2 className="section-title">Send your resume</h2>
            <form className="feedback-form" form-data="reset-form" onSubmit={(event) => {
              this.sendForm(event)
            }}>
              {/*feedback-form__label--error*/}
              <input id="vacancyTitle" type="hidden" name='text-155' value={this.state.vacancy_title}/>
              <label className={`feedback-form__label ${cssDef.form_col_3} ${cssDef.form_col_3_1024}`}>
                <span>Name<span>*</span></span>
                {/*feedback-form__input--error*/}
                <input type="text"
                       id="feedback-name"
                       placeholder="Your name"
                       name="fullname"
                       className="feedback-form__input"
                       value={this.state.name}
                       onChange={(event) => {
                         this.setState({name: event.target.value})
                       }}
                />
                <span className="feedback-form__error-msg">Fill out the name</span>
              </label>

              <label className={`feedback-form__label  ${cssDef.form_col_3}  ${cssDef.form_col_3_800}`}>
                <span> Email<span>*</span></span>
                <input type="text"
                       id="feedback-email"
                       placeholder="Email address"
                       name="email"
                       className="feedback-form__input"
                       value={this.state.email}
                       onChange={(event) => {
                         this.setState({email: event.target.value.toLowerCase()})
                       }}
                />
                <span className="feedback-form__error-msg">Fill out the Email</span>
              </label>

              {
                this.state.widthVacanciesList &&
                <div className={`feedback-form__label  ${cssDef.form_col_3} ${cssDef.form_col_3_800}`}>

                  {
                    !!Object.values(this.state.vacancyList).length &&
                    <ListAsRadioButton
                      listVacancyIdPut={this.listVacancyIdPut}
                      listVacancyTitlePut={this.listVacancyTitlePut}
                      list={this.state.vacancyList}
                    />
                  }
                  {
                    !Object.values(this.state.vacancyList).length &&
                    <ListAsRadioButton
                      listVacancyIdPut={this.listVacancyIdPut}
                      listVacancyTitlePut={this.listVacancyTitlePut}
                      list={this.state.vacancyList}
                    />
                  }

                </div>
              }


              <label className="feedback-form__label feedback-form__label--textarea">
                <span>Message<span>*</span></span>
                <textarea id="feedback-message"
                          placeholder="Write your message here"
                          name="description"
                          className="feedback-form__input"
                          value={this.state.message}
                          onChange={(event) => {
                            this.setState({message: event.target.value})
                          }}
                />
                <span className="feedback-form__error-msg">Fill out the message</span>
              </label>

              <div className="re-capcha">
                <ReCAPTCHA
                  sitekey="6LemITscAAAAACsaRgO1UkriXPU8B6kqrfYtC9s8"
                  onChange={onChange}
                  type={'image'}
                  hl={'en'}
                  ref={recaptchaRef}
                />
              </div>

              <div className='attach-file-btn'>
                <label htmlFor="file-type"
                       className="label-modal feedback-form__attach-btn__main-page">
                  Attach the file
                </label>
                <div>
                  <span className='sub-desc'>(up to 10Mb combined size, only .docx, .pdf file)</span>
                  <div
                    className={(Boolean(this.state.pathFile)) ? 'type-file-wrapper show' : 'type-file-wrapper'}>
                    <input type='file'
                           id='file-type'
                           name='file-type'
                           accept=".doc, .docx, .pdf"
                           onChange={() => {
                             const file = document.getElementById("file-type");
                             let value = file.value;
                             value = value.replace('C:\\fakepath\\', '');
                             this.setState({
                               pathFile: value
                             })
                           }}
                    />
                    <span className='attach-file-btn__file-name'>{this.state.pathFile}</span>
                  </div>
                </div>
                <span className="feedback-form__error-attach"
                      id='attach-error'>Please add file with correct file format</span>
              </div>

              <div className="form-btn-block">
                <button className="btn btn--160w btn--upper" type="submit">Send Message</button>
              </div>

              <div className="feedback-form__notification">
                <p
                  className={this.state.showSuccessMsg ? ('feedback-form__success feedback-form__success--show') : ('feedback-form__success')}>Thank
                  you for getting in touch!</p>
                <p
                  className={this.state.showErrorMsg ? ('feedback-form__error feedback-form__error--show') : ('feedback-form__error')}>Unfortunately,
                  message wasn't sent. Please try to send it again or contact us by phone.</p>
              </div>
            </form>
          </div>
        </section>

      </Fragment>
    )
  }

}

export default CvFormVacancyComponent;