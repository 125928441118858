import React, {Component} from "react";
import css from './drop-down-component.module.scss';

class DropDownComponent extends Component {
  constructor(props) {
    super(props)

    const {data} = this.props

    this.valueName = this.props.type.slice(0, this.props.type.length - 1);
    this.state = {
      // showList: null,
      [this.valueName]: null,
      showDropDown: false,
    }

    this.myRef = React.createRef();
    this.showList = this.showList.bind(this)
  }

  showList = (_) => {
    _.stopPropagation();

    const containers = document.getElementsByClassName(css.drop_down_container);
    const drops = document.getElementsByClassName(css.drop_down_list__select);

    Array.from(containers).forEach(item => {
      item.removeAttribute('active');
    })

    Array.from(drops).forEach(item => {
      item.style.display = "none"
    })

    _.target.setAttribute('active', 'active')

    let indexDn = null;

    Array.from(containers).forEach((item, index) => {
      if (item.getAttribute('active') === 'active') {
        indexDn = index;
      }
    })

    Array.from(drops).forEach((item, index) => {
      if (indexDn == index) {
        item.style.display = "flex"
      }
    })
  }

  anyClickClose = (_) => {
    _.stopPropagation();
    if (!_.target.closest(`label`)) {
      const drops = document.getElementsByClassName(css.drop_down_list__select);

      Array.from(drops).forEach(item => {
        item.style.display = "none"
      })
    }
  }

  setValue = (_) => {
    _.stopPropagation();

    this.setState({
      [this.valueName]: _.target.textContent
    })
    this.props.type === 'Specialization:' ?
      this.props.getValuesFromDropDown({
        [this.valueName]: _.target.textContent,
        ID: _.target.id
      })
      :
      this.props.getValuesFromDropDown({
        [this.valueName]: _.target.textContent
      })

    const drops = document.getElementsByClassName(css.drop_down_list__select);

    Array.from(drops).forEach(item => {
      item.style.display = "none"
    })
  }

  setDefaultValue = () => {
    this.setState({
      [this.valueName]: null,
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', e => {
      this.anyClickClose(e);
    })
  }

  componentDidMount() {
    document.addEventListener('click', (e) => {
      this.anyClickClose(e);
    })
    if (this.props.data.length === 0) {
      this.setState({
        [this.valueName]: null,
      })
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.data.length === 0) this.setDefaultValue()
  }

  render() {
    return (<>
      <label ref={this.myRef} className={`${css.drop_down_container}`}
             onClick={this.showList}>
          <span className={`${css.drop_down_type}`}>
            {this.props.type}
          </span>
        <div className={`${css.select_container}`}>
          <p className={`${css.select_title}`}>
            {this.state[this.valueName] === null ? this.props.selectTitle : this.state[this.valueName]}
          </p>
          <span className={`${css.select_btn}`}></span>

          <div className={`${css.drop_down_list__select} ${this.state.showList}`}>
            <div className={`${css.drop_down_list} scroll`}>
              {
                this.props.type === 'Specialization:' ?
                  this.props.itemsList && this.props.itemsList.map((item, index) => <span
                    key={index}
                    id={item.ID}
                    className={this.state[this.valueName] === item.post_title ? `${css.drop_down_list_item} ${css.active}` : `${css.drop_down_list_item}`}
                    onClick={this.setValue}>
                      {item.post_title.split(' ')[0]}
                    </span>)
                  :
                  this.props.itemsList && this.props.itemsList.map((item, index) => <span
                    key={index}
                    className={this.state[this.valueName] === item.name ? `${css.drop_down_list_item} ${css.active}` : `${css.drop_down_list_item}`}
                    onClick={this.setValue}>
                      {item.name}
                    </span>)
              }
            </div>
          </div>

        </div>
      </label>
    </>)
  }
}

export default DropDownComponent;