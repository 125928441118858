import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import css from "./surveyFormRange.module.scss";

const MultiRangeSlider = ({min, max, onChange, multiRangeResetBool}) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);

    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef(null);
    const container = useRef(null);
    const minRangeRef = useRef(null);
    const maxRangeRef = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Reset min and max values when multiRangeReset changes
    useEffect(() => {
        if (multiRangeResetBool) {
            setMinVal(min);
            setMaxVal(max);
            minValRef.current = min;
            maxValRef.current = max;
            range.current.style.left = `0%`;
            range.current.style.width = `100%`;
        }
    }, [multiRangeResetBool]);

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);
        if (range.current) {
            range.current.style.left = `calc(${minPercent}%)`;
            range.current.style.width = `calc(${maxPercent - minPercent}%)`;
            if (minPercent > 50) {
                range.current.style.left = `calc(${minPercent}% - 23px)`;
                range.current.style.width = `calc(${maxPercent - minPercent}% + 23px)`;
            }

            if (maxPercent < 50) {
                range.current.style.width = `calc(${maxPercent - minPercent}% + 23px)`;
                range.current.style.maxWidth = `${container.current.offsetWidth}px`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);
        if (range.current) {
            range.current.style.width = `calc(${maxPercent - minPercent}%)`;
            if (maxPercent < 50) {
                range.current.style.width = `calc(${maxPercent - minPercent}% + 23px)`;
                range.current.style.maxWidth = `${container.current.offsetWidth}px`;
            }
            if (minPercent > 50) {
                range.current.style.left = `calc(${minPercent}% - 23px)`;
                range.current.style.width = `calc(${maxPercent - minPercent}% + 23px)`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({min: minVal, max: maxVal});
    }, [minVal, maxVal, onChange]);

    return (
        <div className={css.container} ref={container}>
            <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                onChange={(event) => {
                    const value = Math.min(Number(event.target.value), maxVal - 0);
                    setMinVal(value);
                    minValRef.current = value;
                }}
                className={`${css.thumb} ${css.thumbLeft}`}
                style={{zIndex: minVal > max - 100 && "5"}}
            />
            <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                onChange={(event) => {
                    const value = Math.max(Number(event.target.value), minVal + 0);
                    setMaxVal(value);
                    maxValRef.current = value;
                }}
                // style={{width: "calc(100% - 28px)", right: "0"}}
                className={css.thumb + " " + css.thumbRight}
            />

            <div className={css.slider}>
                <div className={css.slider__track}/>
                <div ref={range} className={css.slider__range}/>
                <div className={css.slider__leftValue}>
                    <input
                        className={css.slider__range_value_input}
                        value={minVal}
                        readOnly={true}
                        min={min}
                        max={max}
                        ref={minRangeRef}
                        onChange={(e) => {
                            const value = Number(e.target.value.replace(/\D/g, ""));
                            const maxValCurrent = Number(maxRangeRef.current.value);

                            if (value > max) {
                                setMinVal(max);
                            } else if (value < min) {
                                setMinVal(min);
                            } else {
                                setMinVal(value);
                            }
                            if (value > maxValCurrent) {
                                setMinVal(maxVal);
                            }

                        }}
                    />
                </div>
                <div className={css.slider__rightValue}>
                    <input
                        readOnly={true}
                        className={css.slider__range_value_input}
                        value={maxVal}
                        min={min - 1}
                        max={max}
                        ref={maxRangeRef}
                        onChange={(e) => {
                            const value = Number(e.target.value.replace(/\D/g, ""));
                            const minValCurrent = Number(minRangeRef.current.value);

                            if (value > max) {
                                setMaxVal(max);
                            } else if (value < min) {
                                setMaxVal(min);
                            } else {
                                setMaxVal(value);
                            }
                            if (value < minValCurrent) {
                                setMaxVal(minVal);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

MultiRangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    multiRangeResetBool: PropTypes.bool.isRequired,
};

export default MultiRangeSlider;
