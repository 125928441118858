import React, {Component, Fragment} from "react";
import {Link} from 'react-scroll';

import './article_of_blog_navigation.scss';

class ArticleOfBlogNavigationComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      fixedControls: '',
      mobileActive: '',
      offSet: null,
      hide: '',
      activeClass: 'active'
    }

    this.changeScrollY = this.changeScrollY.bind(this);
    this.addMenuActive = this.addMenuActive.bind(this);
    this.deleteMenuActive = this.deleteMenuActive.bind(this);
    this.setActiveFirstLink = this.setActiveFirstLink.bind(this);

    window.addEventListener('scroll', this.changeScrollY);
    window.addEventListener('scroll', this.setActiveFirstLink);
  }

  componentDidMount() {
    this.setState({
      content: this.props.content,
    })
  }

  changeScrollY() {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > this.props.height + 150 && windowHeight < this.props.contentHeight + 100 ? this.setState({fixedControls: 'active_fixed'}) : this.setState({fixedControls: ''});
    }
  }

  addMenuActive(e) {
    this.setState({
      mobileActive: 'mobile-active',
      hide: 'hide'
    })
    e.stopPropagation();
  }

  deleteMenuActive(e) {
    this.setState({
      mobileActive: '',
      hide: ''
    })
    e.stopPropagation();
    console.log('Work');
  }

  setActiveFirstLink() {
    if (window !== undefined) {
      this.props.contentTop > 82 ? this.setState({activeClass: 'active'}) : this.setState({activeClass: ''})
    }
  }

  render() {
    return (
      <Fragment>
        <div className={`article-of-blog_control ${this.state.fixedControls} ${this.state.mobileActive}`}
             onClick={this.deleteMenuActive}>
          <div className='article-of-blog_control-title'>Contents</div>
          <div className={`article-of-blog_control-nav-ico ${this.state.hide}`} onClick={this.addMenuActive}>
            <img src="https://panel.stairwaysoft.com/wp-content/uploads/2020/10/search_bnt_ico2.svg" alt=""/>
          </div>
          <div className='article-of-blog_control-list'>
            {
              this.state.content.map((paragraph, index) => {
                if (index === 0) {
                  return (
                    <Link onClick={this.deleteMenuActive}
                          activeClass={'active'}
                          to={paragraph.title}
                          spy={true}
                          smooth={true}
                          offset={-81}
                          duration={500}
                          key={index}
                          hashSpy={true}
                          ignoreCancelEvents={false}
                          className={this.state.activeClass}>{`${paragraph.title}`}
                    </Link>
                  )
                } else {
                  return (
                    <Link onClick={this.deleteMenuActive}
                          activeClass={'active'}
                          to={paragraph.title}
                          spy={true}
                          smooth={true}
                          offset={-81}
                          duration={500}
                          hashSpy={true}
                          ignoreCancelEvents={false}
                          key={index}>{`${paragraph.title}`}
                    </Link>
                  )
                }
              })
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default ArticleOfBlogNavigationComponent;