import React, {Component, Fragment} from 'react';
import css from './featured-jobs.component.module.scss';
import {Link} from "react-router-dom";

const limitDefault = 1000;

class FeaturedJobsComponent extends Component {

	constructor(prop) {
		super(prop);
		this.state = {
			vacanciesFilter: prop.vacanciesFilter,
			limit: limitDefault,
			vacancies: undefined,
			allJobs: true,
			allVacanciesLink: false
		}
		console.log('prop==>', prop)
	}

	componentDidMount() {
		this.get_data_from_api();
	}

	componentWillUnmount() {
		this.get_data_from_api();
	}

	get_data_from_api = () => {
		fetch(`https://panel.stairwaysoft.com/api/wp/v2/vacancies`).then(response => response.json())
			.then(data => {

				this.setState({
					vacancies: data, vacanciesFilter: data.sort((a, b) => b.hot - a.hot),
				});

				if (data) {
					this.props.getVacancyState && this.props.getVacancyState(
						{
							vacanciesFilter: this.state.vacanciesFilter,
							vacancies: this.state.vacancies,
						}
					)
				}

				if (this.props.limit) {
					this.setState({limit: this.props.limit});
					const allVacanciesLinkValidate = this.state.limit === limitDefault;
					if (!allVacanciesLinkValidate) this.setState({allVacanciesLink: true});
				}

				if (this.props.vacanciesFilter) this.setState({vacanciesFilter: this.props.vacanciesFilter})

			});
	}

	limitedLastItem = (index) => {
		if (this.props.limit && index === this.props.limit - 1) {
			return css.limitedLastItem;
		}
	}

	render() {
		const vacancyControl = this.props.vacanciesFilter || this.state.vacancies;
		return (
			<Fragment>

				<section className={css.vacancies}>
					<div className="container">
						{vacancyControl && vacancyControl.map((data, index) => {
								if (index < this.state.limit) {
									return (
										<div data-slug={data.slug}
												 className={`${css.vacancy_container} 
												 ${this.limitedLastItem(index) || ''}`}
												 key={index}
										>
											<Link to={'/vacancies/' + data.slug} className={css.link_as_all_block}></Link>
											<span className={css.vacancy_top}>
												<h3 className={`${css.vacancy_title} ${data.hot ? css.hot_title : ''}`}>{data.post_title}</h3>
												{!this.state.mobile &&
													<Link
														to={'/vacancies/' + data.slug}
														className={`${css.vacancy_top__link} bottom_fonts`}>
														Apply
													</Link>
												}
											</span>
											<span className={css.vacancy_info}>

												{data.vacancies_filter.location.length && <span className={css.vacancy_info__item}>
													<span className={css.vacancy_info__item_name}>Location: </span>
													<span
														className={css.vacancy_info__item_desc}>{data.vacancies_filter.location.map(a => a.name).join(', ')}</span>
												</span>}
												{data.vacancies_filter.work_type.length && <span className={css.vacancy_info__item}>
													<span className={css.vacancy_info__item_name}>Type of employment:</span>
													<span
														className={css.vacancy_info__item_desc}>{data.vacancies_filter.work_type.map(a => a.name).join(', ')}</span>
												</span>}
												{data.vacancies_filter.project.length && <span className={css.vacancy_info__item}>
													<span className={css.vacancy_info__item_name}>Project:</span>
													<span
														className={css.vacancy_info__item_desc}>{data.vacancies_filter.project.map(a => a.name).join(', ')}</span>
												</span>}
												{data.vacancies_filter.specialization.length && <span className={css.vacancy_info__item}>
                      <span
												className={css.vacancy_info__item_name}>Specialization: </span>
													<span
														className={css.vacancy_info__item_desc}>{data.vacancies_filter.specialization.map(a => a.name).join(', ')}</span>
												</span>}
											</span>
											{this.state.mobile &&
												<Link to={'/vacancies/' + data.slug}
															className={`${css.vacancy_top__link} bottom_fonts`}
												>
													Apply
												</Link>
											}
										</div>
									)
								}
							}
						)}
						{this.state.allVacanciesLink &&
							<span className={`${css.link_more_center} ${css.w100}`}>
								<Link to={'/vacancies'} className={css.vacancy_link}>All Jobs</Link>
							</span>
						}
					</div>
				</section>

			</Fragment>
		)
	}
}

export default FeaturedJobsComponent;