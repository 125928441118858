import React, {Component, Fragment} from "react";
import css                          from './how-it-work.module.scss';

class HowItWorkComponent extends Component {
  constructor(props) {
    super(props);
    this.state =
      {
        how_it_works: props.how_it_works,
      };
  }

  componentWillUnmount() {
    this.setState(
      {
        how_it_works: {},
      }
    )
  }

  render() {
    let countItems = this.state.how_it_works.length;
    return (
      <Fragment>
        <section className={`section-bg ${css.section}`}>
          <div className="container">
            <h2 className="section-title">How it works</h2>
          </div>
          <div className={`container ${css.row}`}>
            {
              this.state.how_it_works && this.state.how_it_works.map(
                (item, key) =>
                  <div className={css.item} key={key} style={{zIndex: countItems-- || 0}}>
                    <h3
                      className={
                        this.state.how_it_works.length - 1 === key ?
                          `${css.item_title} ${css.item_title_last}` :
                          `${css.item_title}`
                      }
                    >
                      {item.title}
                      <span
                        className={
                          this.state.how_it_works.length - 1 === key ?
                            `${css.item_title_gray_row_last} ${css.item_title_gray_row}` :
                            `${css.item_title_gray_row}`
                        }
                      >
                        <span
                          className={
                            this.state.how_it_works.length - 1 === key ?
                              `${css.item_title_gray_row_top_line_last} ${css.item_title_gray_row_top_line}` :
                              `${css.item_title_gray_row_top_line}`
                          }></span>
                        <span
                          className={
                            this.state.how_it_works.length - 1 === key ?
                              `${css.item_title_gray_row_under_line_last} ${css.item_title_gray_row_under_line}` :
                              `${css.item_title_gray_row_under_line}`
                          }></span>
                      </span>
                    </h3>
                    <div className={css.desc} dangerouslySetInnerHTML={{__html: item.description}}></div>
                  </div>
              )
            }
          </div>
        </section>
      </Fragment>
    );
  }
}

export default HowItWorkComponent;