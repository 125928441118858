import React, {Component, Fragment} from 'react';
import css from "./vacaccy-filter.module.scss";
import cssParent from "../featured-jobs/featured-jobs.component.module.scss";
import FeaturedJobsComponent from "../featured-jobs/featured-jobs.component";

export const defaultEnum = {
	defaultValue: 'All position'
}

export class VacancyFilterComponent extends Component {
	constructor(prop) {
		super(prop)
		this.state = {
			filterSet: {
				expertise: [], work_type: [], specialization: []
			},
			mobile_filter: '',
			mobile: false,
			vacancies: undefined,
			vacanciesFilter: undefined,
			selectTypeStatus: null,
			selectLevelStatus: null,
			selectSpecialisationStatus: null,
			selectTypeValue: defaultEnum.defaultValue,
			selectLevelValue: defaultEnum.defaultValue,
			selectSpecialisationValue: defaultEnum.defaultValue,
			selectArrayOfApiSpecialization: undefined,
			selectArrayOfApiWorkType: undefined,
			selectArrayOfApiExpertise: undefined,
		}
	}

	removeActiveOfSelects = _ => {
		for (let activeItem of document.getElementsByClassName(css.filter_form_label_active)) {
			activeItem.classList.remove(css.filter_form_label_active)
		}
	}

	switchActiveOfSelects = (element) => {
		if (window.innerWidth <= 1024 && element.attributes['data-select-view']) {
			if (element.className.split(' ').some(_ => _ === css.filter_form_label_active)) {
				this.removeActiveOfSelects();
			} else {
				for (let activeItem of document.getElementsByClassName(css.filter_form_label_active)) {
					activeItem.classList.remove(css.filter_form_label_active)
				}
				element.classList.add(css.filter_form_label_active);

			}
		}
	}

	selectTypeController = _ => {
		this.switchActiveOfSelects(_.target);

		if (_.target.getElementsByClassName(`${css.select__active}`).length) {
			this.selectStatusFalse();
		} else {
			this.setState({
				selectTypeStatus: css.select__active, selectLevelStatus: null, selectSpecialisationStatus: null
			})
		}
	}

	selectLevelController = _ => {
		this.switchActiveOfSelects(_.target);

		if (_.target.getElementsByClassName(`${css.select__active}`).length) {
			this.selectStatusFalse();
		} else {
			this.setState({
				selectLevelStatus: css.select__active, selectTypeStatus: null, selectSpecialisationStatus: null
			})
		}
	}

	selectSpecialisationController = _ => {
		this.switchActiveOfSelects(_.target);
		if (_.target.getElementsByClassName(`${css.select__active}`).length) {
			this.selectStatusFalse();
		} else {
			this.setState({
				selectTypeStatus: null, selectLevelStatus: null, selectSpecialisationStatus: css.select__active,
			})
		}
	}

	selectStatusFalse = _ => {
		this.setState({
			selectTypeStatus: null, selectLevelStatus: null, selectSpecialisationStatus: null,
		})
	}

	resize = _ => {
		if (window.innerWidth <= 1024) {
			this.setState({
				mobile: true,
			})

		} else {
			document.querySelector('html').classList.remove('disable_scroll');
			this.setState({
				mobile: false,
			})
		}
	}

	anyClickClose = (e) => {
		if (!e.target.closest(`label`)) {
			this.selectStatusFalse();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('load', this.resize);
		window.removeEventListener('resize', this.resize);

		document.removeEventListener('click', e => {
			this.anyClickClose(e);
		})

	}

	componentDidMount() {
		window.addEventListener('load', this.resize);
		window.addEventListener('resize', this.resize);

		fetch(`https://panel.stairwaysoft.com/api/wp/v2/vacancies-category`).then(response => response.json())
			.then(data => {
				this.setState({
					selectArrayOfApiSpecialization: data.specialization,
					selectArrayOfApiWorkType: data.work_type,
					selectArrayOfApiExpertise: data.expertise,
				});

				for (let item of document.querySelectorAll('input[type=checkbox]')) {

					item.addEventListener('click', function () {
						let array = [];
						for (let label of this.closest(`[data-select-view ]`).querySelector('[data-select-container]').children) {
							if (label.querySelector('input').checked) {
								array.push(label.querySelector('span').innerHTML);
							}
						}

						if (array.length) {
							setStateMethod([this.closest('[data-select-view]').querySelector('[data-select-view-value]').attributes['data-select-view-value'].value], array.join(', '));

						} else {
							setStateMethod([this.closest('[data-select-view]').querySelector('[data-select-view-value]').attributes['data-select-view-value'].value], defaultEnum.defaultValue);
						}
					})
				}
				document.addEventListener('click', (e) => {
					this.anyClickClose(e);
				})

			}).then(_ => _).catch(_ => {
		});

		const setStateMethod = (key, value) => {
			this.setState({
				[`${key}`]: value
			})
		}

		document.querySelector('[data-filter-form-submit]').addEventListener('click', _ => {

			this.mobile_filter_close();
			const arrayFilter = {}
			for (let item of document.querySelectorAll('[data-select-view-value]')) {
				arrayFilter[item.attributes['data-select-view-value'].value] = [];

				for (let input of item.parentElement.querySelector('[data-select-container]').children) {
					if (input.children[0].checked) {
						arrayFilter[item.attributes['data-select-view-value'].value].push(input.children[1].innerHTML)
					}
				}

			}

			if (arrayFilter.selectSpecialisationValue.length || arrayFilter.selectLevelValue.length || arrayFilter.selectTypeValue.length) {

				const selectedFilter = this.state.vacancies.filter((data) => {
					return !arrayFilter.selectLevelValue.length || (data.vacancies_filter.expertise && data.vacancies_filter.expertise.some(type => {
						return arrayFilter.selectLevelValue.some(_ => _ === type.name);
					}))
				}).filter((data) => {
					return !arrayFilter.selectSpecialisationValue.length || (data.vacancies_filter.specialization && data.vacancies_filter.specialization.some(type => {
						return arrayFilter.selectSpecialisationValue.some(_ => _ === type.name);
					}))
				}).filter((data) => {
					return !arrayFilter.selectTypeValue.length || (data.vacancies_filter.work_type && data.vacancies_filter.work_type.some(type => {
						return arrayFilter.selectTypeValue.some(_ => _ === type.name);
					}))
				});

				this.setState({
					vacanciesFilter: selectedFilter.sort((a, b) => b.hot - a.hot)
				})

			} else {
				this.setState({
					vacanciesFilter: this.state.vacancies.sort((a, b) => b.hot - a.hot)
				})

			}
			if (this.state.mobile) {
				this.setState({
					mobile_filter: '',

				})
				document.querySelector(`[class="${css.mobile_filter_button}"]`).scrollIntoView({
					behavior: "smooth", block: "start"
				})
			} else {
				document.querySelector(`[class="${cssParent.vacancies}"]`).scrollIntoView({
					behavior: "smooth", block: "start"
				})
			}

		})

	}

	mobile_filter_open = _ => {
		document.querySelector('html').classList.add('disable_scroll');
		this.setState({
			mobile_filter: css.filter_mb_active,
		})
	}

	mobile_filter_close = _ => {
		document.querySelector('html').classList.remove('disable_scroll');
		this.selectStatusFalse();
		_ && this.removeActiveOfSelects(_.target);
		this.setState({
			mobile_filter: '',
		})
	}

	clearSelectCheckBoxes = _ => {
		switch (_.target.closest('[data-select-view]').querySelector('[data-select-view-value]').attributes['data-select-view-value'].value) {
			case 'selectLevelValue':
				this.setState({selectLevelStatus: null, selectLevelValue: defaultEnum.defaultValue,})
				break;
			case 'selectTypeValue':
				this.setState({
					selectTypeStatus: null, selectTypeValue: defaultEnum.defaultValue,
				})
				break;
			case 'selectSpecialisationValue':
				this.setState({
					selectSpecialisationStatus: null, selectSpecialisationValue: defaultEnum.defaultValue,
				})
				break;
		}
		for (let item of _.target.closest('[data-select-view]').querySelectorAll('input[type="checkbox"]')) {
			item.checked = false;
		}
	}

	getVacancyState = (value) => {
		this.setState(value);
	}

	render() {
		const with100 = {
			width: '100%'
		}

		return (
			<Fragment>
				<h2 className="section-title">Featured jobs</h2>

				{
					this.state.mobile && <div className="container" style={with100}>
						<div className={css.mobile_filter_button} onClick={this.mobile_filter_open}>
							filters
						</div>
					</div>
				}

				<section className={`${css.filter} ${css.section} ${this.state.mobile_filter}`}>
					<div className={css.filter_background} onClick={this.mobile_filter_close}></div>
					<div className={`container ${css.w100} ${css.filter_container} scroll`}>
						<form data-filter-form={true} action="" className={css.filter_form}>

							<label
								data-select-view={true}
								onClick={this.selectTypeController}
								htmlFor="type"
								className={`${css.filter_form_label}`}
							>
								<span className={css.filter_form_label_span}>Work type: </span>
								<input
									data-select-view-value='selectTypeValue'
									id="type"
									type="text"
									disabled={true}
									className={css.filter_form_label_input}
									value={this.state.selectTypeValue}
								/>

								<div data-select={true}
										 className={`${css.filter_form_label__select} ${this.state.selectTypeStatus}`}>
									<span className={css.clearOptions} onClick={this.clearSelectCheckBoxes}>clear</span>

									<div data-select-container={true} className={`${css.select_container} scroll`}>
										{this.state.selectArrayOfApiWorkType && this.state.selectArrayOfApiWorkType.map((data, index) =>

											<label className='disabled' key={index}>
												<input type="checkbox"/>
												<span className={`${css.filter_form_label__select_span}`}>
                              {data.name}
                          </span>
											</label>)}
									</div>
								</div>

							</label>

							<label data-select-view={true} onClick={this.selectLevelController} htmlFor="level"
										 className={css.filter_form_label}>

								<span className={css.filter_form_label_span}>Tech level: </span>
								<input
									data-select-view-value='selectLevelValue'
									id="level" type="text"
									disabled={true}
									value={this.state.selectLevelValue}
									className={css.filter_form_label_input}
								/>

								<div className={`${css.filter_form_label__select} ${this.state.selectLevelStatus}`}>
									<span className={css.clearOptions} onClick={this.clearSelectCheckBoxes}>clear</span>

									<div data-select-container={true} className={`${css.select_container} scroll`}>
										{this.state.selectArrayOfApiExpertise && this.state.selectArrayOfApiExpertise.map((data, index) =>

											<label className='disabled' key={index}>
												<input type="checkbox"/>
												<span className={`${css.filter_form_label__select_span}`}>
                              {data.name}
                          </span>
											</label>)}

									</div>
								</div>


							</label>

							<label data-select-view={true} onClick={this.selectSpecialisationController} htmlFor="specialization"
										 className={css.filter_form_label}>
								<span className={css.filter_form_label_span}>Specialization: </span>
								<input
									data-select-view-value={'selectSpecialisationValue'}
									id="specialization"
									type="text"
									disabled={true}
									value={this.state.selectSpecialisationValue}
									className={css.filter_form_label_input}/>

								<div className={`${css.filter_form_label__select} ${this.state.selectSpecialisationStatus}`}>
									<span className={css.clearOptions} onClick={this.clearSelectCheckBoxes}>clear</span>

									<div data-select-container={true} className={`${css.select_container} scroll`}>
										{this.state.selectArrayOfApiSpecialization && this.state.selectArrayOfApiSpecialization.map((data, index) =>

											<label className='disabled' key={index}>
												<input type="checkbox"/>
												<span className={`${css.filter_form_label__select_span}`}>
                              {data.name}
                          </span>
											</label>)}

									</div>
								</div>

							</label>

							<input data-filter-form-submit={true} type="button" value="search"
										 className={`${css.filter_form_label_input__button} bottom_fonts`}/>
						</form>
					</div>
				</section>

				<FeaturedJobsComponent
					vacanciesFilter={this.state.vacanciesFilter}
					getVacancyState={this.getVacancyState}
				/>

			</Fragment>
		)
	}
}