import React, {Component, Fragment} from "react";
import LoaderComponent from "../../reuse/loader/Loader.component";
import HeaderComponent from "../../reuse/header/header.component";
import FooterComponent from "../../reuse/footer/footer.component";
import ConnectComponent from "../../reuse/connect/connect.component";
import AboutHomeComponent from "../mainPage/about/about.component";
import CvFormVacancyComponent from "../../reuse/cv-form/cv-form.vacancy.component";
import {VacancyFilterComponent} from "../../reuse/vacancies/vacancy-filter/vacancy-filter.component";
import Modal from "react-awesome-modal";

class VacanciesComponent extends Component {
	constructor(props) {

		super(props);
		window.scrollTo(0, 0);
		this.state = {
			showLoader: true,
		};

	}

	componentWillReceiveProps(nextProps, nextContext) {

		const statusObj = nextProps.dataStatus;

		this.setState({
			gotAboutHome: statusObj.gotAboutHome,
		});
	}

	componentDidMount() {
		this.setState({
			showLoader: false,
		});
	}

	closeModal = (a) => {
		this.setState({
			visible: false
		})
	}

	modalThankYouIsVisible = () => {
		if (this.state.visible) this.setState({visible: false});
		this.setState({modalThankYouVisible: true});
	}

	render() {
		return (
			<Fragment>
				<LoaderComponent visible={this.state.showLoader}/>
				<Modal visible={this.state.modalThankYouVisible} effect="fadeInLeft"
							 onClickAway={() => this.setState({modalThankYouVisible: false})}>
					<section data-section-modal className="section-bg scroll">
						<input type={"button"} className='close-modal-btn'
									 onClick={() => this.setState({modalThankYouVisible: false})}/>
						<div className="modal-massage__container">
							<span className="modal-massage__title">Thank you for your application</span>
							<span className="modal-massage__sub-title">Our recruiters will contact you soon</span>
							<button className="top-nav__btn btn btn--upper modal-massage__btn"
											onClick={() => this.setState({modalThankYouVisible: false})}>close
							</button>
						</div>
					</section>
				</Modal>

				<HeaderComponent/>
				<section className='career-img-section'
								 style={{background: 'url("../img/vacancies/vacancy_bg.jpg") no-repeat center'}}>
					{/*<section className='career-img-section career-img-section-size-cover'*/}
					{/*				 style={{*/}
					{/*					 background: 'url("../img/vacancies/vacancy_new_bg_2.png")',*/}
					{/*				 }}>*/}
					<div className='career-img-section__text-block'>
						<h1 className='career-img-section__title'>
							Become part of the Stairway Soft team!
						</h1>
						<div className='career-img-section__description'>
							<p className='career-img-section__paragraph'>
								Widely used for development of content management systems and project management tools, we have a wide
								experience in using PHP for multiple consumer facing applications in network administration, social
								networking and media domains.
							</p>
						</div>
					</div>
				</section>

				<VacancyFilterComponent/>

				<AboutHomeComponent updateData={this.props.updateData}/>
				<ConnectComponent/>
				<CvFormVacancyComponent modalThankYouIsVisible={this.modalThankYouIsVisible}/>
				<FooterComponent/>
			</Fragment>
		)
	}
}

export default VacanciesComponent
