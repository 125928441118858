import React, {Component, Fragment} from 'react';
import css from '../../reuse/vacancies/vacancy-filter/vacaccy-filter.module.scss';
import {defaultEnum} from "../vacancies/vacancy-filter/vacancy-filter.component";
import {Generate_hexService} from "../generate_hex/generate_hex.service";
import cssModule from './list-as-radio-button.module.scss';

export class ListAsRadioButton extends Component {

	constructor(prop) {
		super(prop);
		this.state = {
			selectedValue: '',
			elementsHexId: Generate_hexService.create(100),
			list: this.props.list || [],
		}

	}

	openButton = (t) => {
		for (let item of t.target.children) {
			if (item.attributes['data-select']) {
				item.classList.add(css.select__active);
			}
		}

		this.selectRadioButtonAddEvent();
	}

	closeButton = (t) => {

		const clickOnRadio = t.target.attributes['name'];
		const clickOnRadioValidate = clickOnRadio && clickOnRadio.value === this.state.elementsHexId;

		const validate = !!t.target.closest(`#radio_list_${this.state.elementsHexId}`)
			|| t.target.attributes.name === this.state.elementsHexId;

		if (!validate || clickOnRadioValidate) {
			for (let q of document.querySelectorAll(`.${css.select__active}`)) {
				q.classList.remove(css.select__active);
			}
		}
	}

	selectRadioButtonAddEvent = () => {
		for (let item of document.querySelectorAll(`input[name="${this.state.elementsHexId}"]`)) {
			item.addEventListener('click', this.selectRadioButton);
		}
	}

	selectRadioButtonRemoveEvent = () => {
		for (let item of document.querySelectorAll(`input[name="${this.state.elementsHexId}"]`)) {
			item.removeEventListener('click', this.selectRadioButton);
		}
	}

	selectRadioButton = (t) => {

		if (t.target.closest('.disabled').querySelector('span')) {
			this.setState({selectedValue: t.target.closest('.disabled').querySelector('span').innerText});
			this.props.listVacancyTitlePut(t.target.closest('.disabled').querySelector('span').innerText);
			this.closeButton(t);
		}
	}

	closedAllListAddEvent = () => {
		window.addEventListener('click', this.closeButton);
	}

	closedAllListRemoveEvent = () => {
		window.removeEventListener('click', this.closeButton);
	}

	componentDidMount() {
		const element = document.querySelector(`#radio_list_${this.state.elementsHexId}`);
		element.addEventListener('click', this.openButton);
		this.closedAllListAddEvent();
		this.props.listVacancyIdPut(this.state.elementsHexId);
	}

	componentWillUnmount() {
		const element = document.querySelector(`#radio_list_${this.state.elementsHexId}`);
		element.removeEventListener('click', this.openButton);
		this.closedAllListRemoveEvent();
		this.selectRadioButtonRemoveEvent();
	}

	render() {

		return (
			<Fragment>
				<label
					id={`radio_list_${this.state.elementsHexId}`}
					data-select-view={true}
					onClick={this.selectTypeController}
					htmlFor="type"
					className={`${css.filter_form_label} ${cssModule.zIndex2} ${cssModule.h100} ${cssModule.w100} ${cssModule.filter_form_label}`}
				>

					<span className={`${css.filter_form_label_span} ${cssModule.filter_form_label_span}`}>Specialization*: </span>

					<input
						data-select-view-value='selectTypeValue'
						id="type"
						type="text"
						disabled={true}
						className={`${css.filter_form_label_input} ${cssModule.filter_form_label_input}`}
						value={this.state.selectedValue || defaultEnum.defaultValue}
					/>

					<div data-select={true}
							 className={`${css.filter_form_label__select} ${cssModule.filter_form_label__select}`}>

						<div data-select-container={true} className={`${css.select_container} scroll`}>
							{this.state.list && this.state.list.map(
								(name, index) =>
									<label className='disabled' key={index}>
										<input name={this.state.elementsHexId} type="radio" className={`${cssModule.hidden}`}/>
										<span className={`${css.filter_form_label__select_span}`}>
											{name}
										</span>
									</label>
							)
							}
						</div>
					</div>

					<span
						className={`feedback-form__error-msg ${cssModule.feedback_form__error_msg}`}>Specialisation is required</span>

				</label>

			</Fragment>

		)

	}

}