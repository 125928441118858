import React, {Fragment, Component} from "react";
import './principal.scss';

class PrincipalsComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <Fragment>
        <section className={this.props.marginBottom === true ? "principals mb_0": "principals"}>
          <div className="container">
            <h2 className='principals_title'>Our operating principles</h2>
            <div className="principals_content">
              <div className="principals_group">
                <h3 className="principals_group-title">Quick Dedicated Team Setup</h3>
                <p className="principals_group-text">
                  For customers who are faced with the need for rapid scaling of their development team, we provide remote dedicated personnel, that are fully
                  committed to your project.<br/><br/>Without spending a lot of precious time searching and hiring your own employees, you can start
                  working with your remote team in a few weeks.<br/><br/>Our process is as simple as it can be and consists
                  of only a few steps:
                </p>
                <ul className="principals_group-list">
                  <li className="principals_group-item">Getting detailed requirements;</li>
                  <li className="principals_group-item">Putting the team together;</li>
                  <li className="principals_group-item">Coordinating all developers with the client;</li>
                  <li className="principals_group-item">Get to work.</li>
                </ul>
              </div>

              <div className="principals_group">
                <h3 className="principals_group-title">Reduce Expenses for Recruitment</h3>
                <p className="principals_group-text">
                  You will save not only financial resources for
                  recruiting and on-boarding staff, but also precious time, having received a ready-to-work team within a few weeks.
                  <br/><br/>
                  There is no need to search for people who will
                  participate on the project.
                  StairwaySoft will provide you with highly qualified specialists in the field of cutting edge technologies.
                  <br/><br/>
                  Of course, there are additional savings on
                  workspaces, office expenses, training and introduction to your company’s processes.
                </p>
              </div>

              <div className="principals_group">
                <h3 className="principals_group-title">Long-Term Cooperation</h3>
                <p className="principals_group-text">
                  Instead of managing a large team of developers,
                  you get one point of contact — a project manager who keeps you abreast of all the affairs on the
                  project and provides all the necessary information.
                  <br/><br/>
                  While working on a complex project, it is not
                  recommended to change developers.
                  We are also interested in putting together a team that will be with you for as long as necessary.
                  <br/><br/>
                  A team is the most valuable asset so we won’t
                  randomly reassign personnel, but instead we’ll keep them dedicated to your company.
                </p>
              </div>
            </div>
          </div>

        </section>
      </Fragment>
    )
  }
}

export default PrincipalsComponent;