import React, {Component, Fragment} from 'react';
import css from './first-screen.module.scss';

export class FirstScreenComponent extends Component {
  visibleVideo = true;

  constructor(prop) {
    super(prop);
    console.log(prop)
    // window.addEventListener('resize', () => {
    //   window.innerWidth <= 830 ? this.visibleVideo = false : this.visibleVideo = true;
    //   console.log(this.visibleVideo)
    // })
  }

  render() {
    // const imageParallaxUrl = {
    //   backgroundImage: `url(${this.props.first_screen_image})`,
    // };


    return (
      <Fragment>
        {/*{this.visibleVideo && <div>wdwwdweew</div>}*/}
        <div className={`${css.df} ${css.w100} ${css.mb_150} ${css.position_relative}`}>
          <div
            className={`${css.lite_parallax} ${css.z_index_minus_1} ${css.w100} ${css.video_container}`}
            // style={css.imageParallaxUrl}
          >
            {/*<img*/}
            {/*  className={`${css.opacityNull}  ${css.w100} ${css.img}`}*/}
            {/*  src={this.props.first_screen_image}*/}
            {/*/>*/}
            <video
              className={`${css.w100} ${css.video}`}
              muted={true}
              autoPlay={true}
              controls={false}
              src={this.props.career_first_screen_video}
              loop={true}
              poster={this.props.first_screen_image}
            />
          </div>

          <div
            className={`${css.z_index_1} ${css.position_absolute} ${css.w100} ${css.position}`}>
            <div className="container">
              <p
                className={`${css.gradient_violet} ${css.mb_minus_150} ${css.title} ${css.title_adaptive}`}>
                Become one of StairwaySoft family!
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

