import React, {Component, Fragment} from "react";
import './feedback.scss';
import cssModule from './feedback.module.scss';

class FeedbackComponent extends Component {
  element = React.createRef();

  constructor(props) {
    super(props);
//		console.log(this.props.data)
    this.state = {
      feedbacks: this.props.data,
      showBlock: '',
      elementPosition: '',
      elementHeight: '',
      id: 0,
      counter: [
        {step_0: '0.0'},
        {step_1: '0.0'},
        {step_2: '0.0'},
        {step_3: '0.0'},
      ],
      startCounter: false,
      showBlocks: false,
      counterShow: 0,
      currentSmileAnimation: 1,
    }

    this.getPosition = this.getPosition.bind(this);
//		console.log(this.props);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.getPosition);

    this.items = this.state.counter.values();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getPosition);
  }

  intervalChange = () => {
    if (this.items.next().done === false) {
      this.interval();
      this.intervalChange(this.items);
      window.removeEventListener('scroll', this.getPosition);
    } else {
      return false;
    }
  }

  interval() {

    this.intervalId = setInterval(() => {
      if (this.state.id > 3) {
        return clearInterval(this.intervalId);
      }

      const time = [...this.state.counter]
      time[this.state.id][`step_${this.state.id}`] = +time[this.state.id][`step_${this.state.id}`] + 0.1;
      this.setState({
        counter: time
      })
      if (this.state.counter[this.state.id][`step_${this.state.id}`].toFixed(1) === this.state.feedbacks[this.state.id].finish_number.toString()) {
        this.finishIconAnimation(this.state.id);
        this.setState({id: this.state.id + 1});
        return clearInterval(this.intervalId);
      }
    }, 90);
  }

  getPosition() {
    if (this.element.current) {
      this.setState({elementPosition: this.element.current.getBoundingClientRect().bottom});
      this.setState({elementHeight: this.element.current.getBoundingClientRect().height})
      if (this.state.elementHeight + 310 >= this.state.elementPosition && this.state.elementPosition > 0) {
        this.setState({showBlock: 'show', startCounter: true});
        this.runIconAnimation();
      }
    }
  }

  smiles = (index) => {
    return (
      <div className={cssModule.smile}>
        <div className={cssModule.smile_container} data-icon-number={index}>
          {this.props.feedback_rating_all_smiles && this.props.feedback_rating_all_smiles.map((_, index) => {
              return (
                <span key={index} className={`${cssModule.icon}`}>
								{_.smile}
								</span>
              )
            }
          )}
        </div>
      </div>
    )
  };

  finishIconAnimation = (id) => {
    const finishAnimations = this.props.data.map(_ => _.finish_icons);
    const items = document.querySelectorAll('[data-icon-number]');
    if (items && items[id]) items[id].innerHTML = `<span class="${cssModule.icon} ${cssModule.icon_active_finish}">${finishAnimations[id]}</span>`;
  }

  runIconAnimation = () => {

    const items = document.querySelectorAll('[data-icon-number]');
    let count = 0;

    const intervalOfParent = setInterval(() => {
      const item = items[this.state.id];
      if (item) {
        const qty = item.children.length;

        for (let icon of item.children) {
          icon.classList.remove(cssModule.icon_active);
        }

        item.children[count].classList.add(cssModule.icon_active);

        count++;

        if (qty === count) count = 0;
        if (qty === count) clearInterval(intervalOfParent);

      }

    }, 150)

  }

  render() {
    return (
      <Fragment>

        <section className="feedback" ref={this.element}>
          <div className="container" ref={this.getHeight}>
            <h1 className="feedback_title" onClick={this.intervalChange}>
              {this.props.title && this.props.title}
            </h1>

            <div className="feedback_container">
              {
                this.state.showBlock === 'show' &&
                this.state.feedbacks.map((feedback, index) => {
                  return (
                    <div className={'feedback_item feedback_item-active'} key={index} id={feedback.id}>

                      {this.smiles(index)}

                      <p
                        className="feedback_timer">{Number(this.state.counter[index][`step_${index}`]).toFixed(1)}</p>
                      <p className="feedback_text">{feedback.text}</p>
                      {this.intervalChange()}
                    </div>
                  )
                })

              }
            </div>
          </div>
        </section>
      </Fragment>
    )

  }
}

export default FeedbackComponent;