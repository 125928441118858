import React, {Component, Fragment} from "react";
import {Link} from 'react-router-dom';
import HeaderComponent from "../../reuse/header/header.component";
import FooterComponent from "../../reuse/footer/footer.component";
import LoaderComponent from "../../reuse/loader/Loader.component";
import NotFound from "../NotFound";
import ArticleOfBlogNavigationComponent from "./article_of_blog_navigation/article_of_blog.navigation";
import {AppendToHeadService} from "../../reuse/append-to-head/append-to-head.service";
import './article_of_blog.scss';

class ArticleOfBlogComponent extends Component {
	element = React.createRef();

	constructor(props) {
		super(props);

		window.scrollTo(0, 0);

		this.state = {
			article: [],
			gotArticle: false,
			showLoader: true,
			errorPage: false,
			elementHeight: '',
			contentHeight: null,
			contentTop: null,
		}

		this.getHeight = this.getHeight.bind(this);
		this.getPosition = this.getPosition.bind(this);
		this.getArticle(props.match.params.id);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.getPosition);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.getPosition);
	}

	getDate(time) {
		return new Date(time).toDateString().split(' ').slice(1).join(' ');
	}

	getHeight(element) {
		if (element && !this.state.elementHeight)
			this.setState({elementHeight: element.clientHeight});
	}

	getArticle(slug) {
		fetch('https://panel.stairwaysoft.com/api/wp/v2/single-blog/?slug=' + slug)
			.then(responce => responce.json())
			.then(data => {
				if (data[0].content && data[0].title && data[0].description && data[0].post_name) {
					this.setState({
						article: data[0],
						showLoader: false,
						gotArticle: true,
					});

					const meatDate = `
            <meta property="og:url" content="https://stairwaysoft.com${this.props.location.pathname}" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="${data[0].title}" />
            <meta property="og:description" content="[${data[0].description}]" />
            <meta property="og:image" content="${data[0].first_screen_image}" />
          `;

					AppendToHeadService.prototype.add(meatDate);

				} else {
					this.setState({
						showLoader: false,
						errorPage: true,
					})
				}
			}).catch(error => {
			this.setState({
				showLoader: false,
				errorPage: true,
			})
			console.error(error);
		})
	}

	getPosition() {
		if (this.element.current) {
			this.setState({
				contentHeight: this.element.current.getBoundingClientRect().height,
				contentTop: this.element.current.getBoundingClientRect().top
			})
		}
	}

	render() {
		const {title, content, category, date, first_screen_image} = this.state.article;
		console.log(this.state.article)
		const checkArticleReady = this.state.gotArticle && title && content && category && date;

		if (this.state.errorPage === true) {
			return (<NotFound/>)
		} else if (this.state.showLoader === true) {
			return (<LoaderComponent/>)
		} else {
			return (
				<Fragment>
					{checkArticleReady &&
						<Fragment>
							<LoaderComponent visible={this.state.showLoader}/>

							<HeaderComponent/>
							<section ref={this.getHeight}
											 className='article-of-blog_header'
											 style={{background: 'url("' + first_screen_image + '") no-repeat'}}>
								<div className='article-of-blog_header_text_block'>
									<span className='article-of-blog_header_category'>{category}</span>
									<h1 className='article-of-blog_header_title'>{title}</h1>
									<span className='article-of-blog_header_date'>{this.getDate(date)}</span>
								</div>
							</section>
							<section className="section_article-of-blog">
								<ArticleOfBlogNavigationComponent content={content}
																									height={this.state.elementHeight}
																									contentHeight={this.state.contentHeight}
																									contentTop={this.state.contentTop}/>
								<div className="article-of-blog_content" ref={this.element}>
									{
										content.map(paragraph => {
												return (
													<div key={paragraph.title} className={paragraph.title} id={paragraph.title}>
														<h3>{`${paragraph.title}`}</h3>
														<div dangerouslySetInnerHTML={{__html: paragraph.text_editor}}></div>
													</div>
												)
											}
										)
									}
								</div>
							</section>
							<div className='provided_container provide_blog'>
								<div className="container provide-wrapper">
									<span className='provide_blog-text'>More articles can be found on the blog page</span>
									<Link className='provide_blog-link' to="/blog">back to blog</Link>
								</div>
							</div>
							<FooterComponent/>
						</Fragment>
					}
				</Fragment>
			)
		}
	}
}

export default ArticleOfBlogComponent;