import React, {Component, Fragment} from 'react';
import css from './first-screen.module.scss';

export class FirstScreenComponent extends Component {
	constructor(prop) {
		super(prop);
	}

	render() {
		const imageParallaxUrl = {
			backgroundImage: `url(${this.props.first_screen_image})`,
		};

		return (
			<Fragment>
				<div className={`${css.df} ${css.w100} ${css.mb_150} ${css.position_relative}`}>
					<div
						className={`${css.lite_parallax} ${css.z_index_minus_1} ${css.w100}`}
						style={imageParallaxUrl}
					>
						<img className={`${css.opacityNull}  ${css.w100} ${css.img}`}
								 src={this.props.first_screen_image} alt=""/>
					</div>

					<div
						className={`${css.z_index_1} ${css.position_absolute} ${css.w100} ${css.position}`}>
						<div className="container">
							<p
								className={`${css.gradient_violet} ${css.mb_minus_150} ${css.title} ${css.title_adaptive}`}>
								{this.props.title}
							</p>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

