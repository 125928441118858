import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";

class ProvideHomeComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      provide_title: '',
      provide_items: [],
      background: false,
    };

    this.getServiceList();
  }

  componentDidMount() {
    this.setState({
      background: this.props.gradientBg
    })
  }

  getServiceList() {
    fetch('https://panel.stairwaysoft.com/api/wp/v2/pages/1120')
      .then(response => response.json())
      .then(response => {
//        console.clear();
//        console.log('provide', response);
        this.setState({
          provide_title: response.data.provide_title,
          provide_items: response.data.provide_items,
        })
      });
  }

  render() {
    const title = this.state.provide_title;
    const items = this.state.provide_items;
    console.log('provide_title', title);
    return (
      <Fragment>
        <section className="provide-section disable_scroll">
          <div className="container">

            <h2 className="section-title">{title}</h2>

            <div className='provide-container'>
              {items.map((item, index) => {
                  return (
                    <div key={index} className="provide-item">
                      <h3 className='provide-item_title'>
                        <img className='provide-item_title-img' src={item.icon.sizes.large}
                             alt=""/>
                        {item.title}
                      </h3>
                      <ul>
                        {item.list.map((li, index) =>
                          <li key={index}>{li.text}</li>
                        )}
                      </ul>
                    </div>
                  )
                }
              )}
            </div>
            {
              this.state.background === true ?  <div></div>  :  <div className="provide-bottom-space"></div>
            }
            <div className={this.state.background === true ? "provide-container provide_map-bg" : "provide-container provide_map"}>
              <span className={this.state.background === true ? 'provide_map-bg_text' : 'provide_map-text'}>We invite you to familiarize yourself with the roadmap for setting up a dedicated team</span>
              <Link to="/dedicated-team-road-map" className={this.state.background === true ? 'provide_map-bg_link' : 'provide_map-link'}>view map</Link>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default ProvideHomeComponent;
