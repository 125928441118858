import React, {Component, Fragment} from "react";
import Modal from 'react-awesome-modal';
import CvFormVacancyComponent from "../../../reuse/cv-form/cv-form.vacancy.component";

class CvFormModalComponent extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      visible: false,
      widthVacanciesList: this.props.widthVacanciesList || false,
    };

  }

  componentDidMount() {
    this.setState({visible: true})
  }

  componentWillUnmount() {
    this.setState({visible: false})
  }

  closeModal = () => {
    this.props.closeModal();
  }

  render() {

    return (
      <Fragment>

        <Modal visible={this.state.visible} width="1200" effect="fadeInLeft" onClickAway={() => this.closeModal()}>
          <section data-section-modal className="section-bg" id='resume-form'>
            <input type={"button"} className='close-modal-btn' onClick={() => this.closeModal()}/>

            <div className="container">
              <CvFormVacancyComponent
                widthVacanciesList={this.state.widthVacanciesList}
                closeModal={this.closeModal}
                modalThankYouIsVisible={this.props.modalThankYouIsVisible}
              />
            </div>
          </section>
        </Modal>

      </Fragment>
    )
  }
}

export default CvFormModalComponent;