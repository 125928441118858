import React, {Component, Fragment} from 'react';
import LoaderComponent from "../../reuse/loader/Loader.component";
import HeaderComponent from "../../reuse/header/header.component";
import FooterComponent from "../../reuse/footer/footer.component";
import ServiceComponent from "../mainPage/services/services.component";
import MainPageFormComponent from "../mainPage/form/main-page-form.component";
import MainContentComponent from "./main-content/main-content.component";
import FirsScreenComponent from "./first-screen/firs-screen.component";

class RoadMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      gotServices: false,
    }

    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const statusObj = nextProps.dataStatus;
    this.setState({
      gotServices: statusObj.gotServices,
    });
  }

  componentDidMount() {
    this.setState({
      showLoader: false,
    })
  }

  componentWillUnmount() {
    this.setState({
      gotServices: false,
    })

  }

  render() {
    return (
      <Fragment>
        <LoaderComponent visible={this.state.showLoader}/>
        <HeaderComponent/>
        <FirsScreenComponent/>
        <MainContentComponent/>
        <ServiceComponent/>
        <MainPageFormComponent/>
        <FooterComponent/>
      </Fragment>
    )
  }
}

export default RoadMapComponent;
