import React, {Component, Fragment} from 'react';
import css from './main-content-component.module.scss'

const stateDefault = {
  showLoader: true,
  data: {
    page_title: undefined,
    list: undefined,
    under_text_of_list: undefined
  },
  sideBarPositionFixed: false,
  sideBarPositionAbsolute: false,
  mobile_view: false,
  mb_scroll_menu: false,
  mb_scroll_menu_animate: false,
}

class MainContentComponent extends Component {
  constructor(prop) {
    super(prop);
    this.state = stateDefault;

    fetch('https://panel.stairwaysoft.com/api/wp/v2/road-map/')
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data
        });
        this.customScroll();
      });

  }

  componentDidMount() {
    window.innerWidth <= 768 && this.setState({mobile_view: true});
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.setState({mobile_view: true})
      } else {
        this.setState({
          mobile_view: false,
          mb_scroll_menu: false,
          mb_scroll_menu_animate: false,
        })

      }
    })
    this.setState({
      showLoader: false,
    })
  }

  componentWillUnmount() {
    this.setState(stateDefault)
  }

  customScroll = () => {
    const sideBarOfTop = document.querySelector('#' + css.left);
    window.addEventListener('scroll', () => {
      for (let item of document.querySelectorAll(`.${css.item}`)) {
        if (item.getBoundingClientRect().top - 90 < 0 && item.getBoundingClientRect().bottom - 85 > 0) {
          for (let q of document.querySelectorAll('[data-scrollelement]')) {
            q.classList.remove(css.left_step_active)
          }

          document.querySelector(`[data-scrollelement="${item.id}"]`).classList.add(css.left_step_active);
          if (this.mobile_view) {
            this.setState({
              mb_scroll_menu: false
            })
          }

        }
      }

      if (sideBarOfTop.getBoundingClientRect().top - 85 <= 0) {
        this.setState({
          sideBarPositionFixed: true,
        })
      } else {
        this.setState({
          sideBarPositionFixed: false,
        })
      }

      if ((sideBarOfTop.getBoundingClientRect().bottom - 557) <= 0) {
        this.setState(
          {
            sideBarPositionAbsolute: true,
          }
        )
      } else {
        this.setState(
          {
            sideBarPositionAbsolute: false,
          }
        )
      }
    })
    if (this.state.data.list) {
      const myThis = this;
      for (let q = 0; q < this.state.data.list.length; q++) {
        document.querySelector('#step_' + q).addEventListener('click', function () {
          myThis.mbScrollMenuClose();
          window.scroll({
            top: document.getElementById(this.id + "_to").offsetTop - 85,
            behavior: "smooth"
          });
        })
      }
    }

  }

  mbScrollMenuOpen = () => {
    this.setState({
      mb_scroll_menu: true
    })

    setTimeout(() => {
      this.setState({
        mb_scroll_menu_animate: true
      })
    }, 200)
  }

  mbScrollMenuClose = () => {
    this.setState({
      mb_scroll_menu_animate: false
    })

    setTimeout(() => {
      this.setState({
        mb_scroll_menu: false
      })
    }, 200)

  }

  render() {
    return (
      <Fragment>
        <section className={css.section}>
          {
            this.state.mobile_view &&
            <div className={css.nav_ico}>
              <img onClick={this.mbScrollMenuOpen}
                   src="https://panel.stairwaysoft.com/wp-content/uploads/2020/10/search_bnt_ico2.svg" alt=""/>
              {this.state.mb_scroll_menu &&
                <div
                  className={`${css.nav_ico_background} ${this.state.mb_scroll_menu_animate && css.nav_ico_background_active}`}
                  onClick={this.mbScrollMenuClose}></div>}
            </div>
          }
          <div className="container">
            <h2 className="section-title">
              {this.state.data.page_title && this.state.data.page_title}
            </h2>
          </div>

          <div className={`${css.df} ${css.fdr} ${css.jcsb} container`}>

            <div className={`${css.left} ${this.state.mb_scroll_menu && css.left_active}`} id={css.left}>

              <div
                className={`${css.df} ${css.fdc} ${this.state.sideBarPositionFixed && css.side_bar_active} ${this.state.sideBarPositionAbsolute && css.side_bar_under_active}`}>
                {
                  this.state.data.list &&
                  <span className={css.left_title}>
                    Contents
                  </span>
                }
                <div className={`${css.df} ${css.fdc}`} data-steps={true}>
                  {
                    this.state.data.list && this.state.data.list.map(
                      (item, index) =>
                        <span key={index.toString()} onClick={this.customScroll}
                              className={`${css.left_step} ${index === 0 && css.left_step_active}`} id={`step_${index}`}
                              data-scrollelement={`step_${index}_to`}>
                        STAGE {index + 1}
                      </span>
                    )
                  }
                </div>
              </div>

            </div>

            <div className={css.right}>
              {
                this.state.data.list && this.state.data.list.map((item, index, key) =>
                  <p key={index.toString()} className={`${css.item} ${css.df} ${css.fdc}`} id={`step_${index}_to`}>
                    {
                      !this.state.mobile_view &&
                      <span className={css.step_way}>
                          <span className={css.step_way__text}>
                            {index + 1}
                          </span>
                        </span>
                    }
                    <span className={css.step_title}>
                      Stage {index + 1}
                    </span>
                    <span className={css.item_title}>
                      {item.title}
                    </span>
                    <span className={css.item_desc}>
                      {item.description}
                    </span>
                  </p>
                )
              }
              <p className={css.items_under_desc}>
                {this.state.data.under_text_of_list && this.state.data.under_text_of_list}
              </p>

            </div>
          </div>

        </section>
      </Fragment>
    )
  }
}

export default MainContentComponent;
