import React, {Component} from 'react';
import css from './surveyForm.module.scss';
import MultiRangeSlider from "./surveyFormRange/surveyFormRange.component";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-awesome-modal";
import ServiceComponent from "../../mainPage/services/services.component";

const recaptchaRef = React.createRef();
const form = React.createRef();
const regionErrorMessage = React.createRef();
const skillErrorMessage = React.createRef();
const nameErrorMessage = React.createRef();
const companyErrorMessage = React.createRef();
const emailFormatErrorMessage = React.createRef();
const emailErrorMessage = React.createRef();
const submitButton = React.createRef();

class SurveyFormComponent extends Component {
    forSeniorFullStack = {
        minDef: 45,
        maxDef: 95
    }
    forMiddleBackend = {
        minDef: 35,
        maxDef: 65
    }
    teamSize = {
        minDef: 1,
        maxDef: 100
    };

    constructor(props) {
        super(props);
        this.state = {
            reCapcheState: false,
            region: null,
            forSeniorFullStack: {min: 45, max: 95},
            forMiddleBackend: {min: 35, max: 65},
            teamSize: {min: 1, max: 100},
            showSuccessModal: false,
            pathFile: null,
            multiRangeReset: false,
            currency: 'USD',
        }
    }

    regionValidationAndValueGetText = (element) => {
        if (element) {
            return element.closest(`.${css.custom_checkbox_label}`)
                .querySelector(`.${css.custom_checkbox_label_text}`).textContent;
        }
        return null;
    }

    regionValidationAndValue = () => {
        const EU_Balkans = form.current.querySelector('input[name="EU_Balkans"]');
        const Ukraine_Moldova_Belarus = form.current.querySelector('input[name="Ukraine_Moldova_Belarus"]');
        const Kazakhstan_Azerbaijan = form.current.querySelector('input[name="Kazakhstan_Azerbaijan"]');
        const Israel_UAE = form.current.querySelector('input[name="Israel_UAE"]');

        const array = [];
        if (EU_Balkans.checked) {
            const value = this.regionValidationAndValueGetText(EU_Balkans);
            array.push(value);
        }
        if (Ukraine_Moldova_Belarus.checked) {
            const value = this.regionValidationAndValueGetText(Ukraine_Moldova_Belarus);
            array.push(value);
        }
        if (Kazakhstan_Azerbaijan.checked) {
            const value = this.regionValidationAndValueGetText(Kazakhstan_Azerbaijan);
            array.push(value);
        }
        if (Israel_UAE.checked) {
            const value = this.regionValidationAndValueGetText(Israel_UAE);
            array.push(value);
        }
        if (!array.length) {
            regionErrorMessage.current.classList.add(css.activeErrorMessage);
            return false;
        }
        regionErrorMessage.current.classList.remove(css.activeErrorMessage);
        return array.join(', ');
    }

    skillValidationAndValue = () => {
        const PHP = form.current.querySelector('input[name="PHP"]');
        const JavaScript = form.current.querySelector('input[name="JavaScript"]');
        const Angular = form.current.querySelector('input[name="Angular"]');
        const React = form.current.querySelector('input[name="React"]');
        const Node_JS = form.current.querySelector('input[name="Node JS"]');
        const custom = form.current.querySelector('#feedback-message-modal');

        const array = [];
        if (PHP.checked) {
            const value = this.regionValidationAndValueGetText(PHP);
            array.push(value);
        }
        if (JavaScript.checked) {
            const value = this.regionValidationAndValueGetText(JavaScript);
            array.push(value);
        }
        if (Angular.checked) {
            const value = this.regionValidationAndValueGetText(Angular);
            array.push(value);
        }
        if (React.checked) {
            const value = this.regionValidationAndValueGetText(React);
            array.push(value);
        }
        if (Node_JS.checked) {
            const value = this.regionValidationAndValueGetText(Node_JS);
            array.push(value);
        }

        if (custom.value.length) {
            array.push(custom.value);
        }

        if (!array.length) {
            skillErrorMessage.current.classList.add(css.activeErrorMessage);
            return false;
        }

        skillErrorMessage.current.classList.remove(css.activeErrorMessage);
        return array.join(', ');
    }

    fieldValidation = (field, errorMessage) => {
        if (!field.value) {
            errorMessage.current.classList.add(css.activeErrorMessage);
            return false;
        }
        errorMessage.current.classList.remove(css.activeErrorMessage);
        return field.value;
    }

    fieldEmailValidation = (field, errorMessage) => {
        if (!field.value) {
            errorMessage.current.classList.add(css.activeErrorMessage);
            return false;
        }
        const emailRegular = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const result = emailRegular.test(String(field.value).toLowerCase());
        if (!result) {
            errorMessage.current.classList.remove(css.activeErrorMessage);
            emailFormatErrorMessage.current.classList.add(css.activeErrorMessage);
            return false;
        }
        emailFormatErrorMessage.current.classList.remove(css.activeErrorMessage);
        errorMessage.current.classList.remove(css.activeErrorMessage);
        return field.value;
    }
    submitForm = () => {

        this.setState({
            region: this.regionValidationAndValue(),
            forSeniorFullStack: `${this.forSeniorFullStack.min}-${this.forSeniorFullStack.max}`,
            forMiddleBackend: `${this.forMiddleBackend.min}-${this.forMiddleBackend.max}`,
            teamSize: `${this.teamSize.min}-${this.teamSize.max}`,
            skill: this.skillValidationAndValue(),
            name: this.fieldValidation(form.current.querySelector('#feedback-name-modal'), nameErrorMessage),
            company: this.fieldValidation(form.current.querySelector('#feedback-company-modal'), companyErrorMessage),
            email: this.fieldEmailValidation(form.current.querySelector('input[name="email"]'), emailErrorMessage),
        })

        if (!this.state.reCapcheState) {
            document.querySelector('.re-capcha').classList.add('error');
        }

        setTimeout(() => {
            if (
                this.state.reCapcheState &&
                this.state.region &&
                this.state.forSeniorFullStack &&
                this.state.forMiddleBackend &&
                this.state.teamSize &&
                this.state.skill &&
                this.state.name &&
                this.state.company &&
                this.state.email
            ) {
                submitButton.current.setAttribute('disabled', 'disabled');
                this.submitFormAction();
            } else {
                let error = document.querySelector(`.${css.activeErrorMessage}`);
                const getElemDistance = (elem) => {
                    let location = 0;
                    if (elem.offsetParent) {
                        do {
                            location += elem.offsetTop;
                            elem = elem.offsetParent;
                        } while (elem);
                    }
                    return location >= 0 ? location : 0;
                };

                const elementLocation = getElemDistance(error) - 200;
                if (error) {
                    window.scrollTo({
                        top: elementLocation,
                        behavior: 'smooth'
                    });
                }
            }
        }, 100);
    }

    submitFormAction = () => {

        const data = {
            region: this.regionValidationAndValue(),
            forSeniorFullStack: `${this.forSeniorFullStack.min}-${this.forSeniorFullStack.max}`,
            forMiddleBackend: `${this.forMiddleBackend.min}-${this.forMiddleBackend.max}`,
            teamSize: `${this.teamSize.min}-${this.teamSize.max}`,
            skill: this.skillValidationAndValue(),
            name: form.current.querySelector('#feedback-name-modal').value,
            company: form.current.querySelector('#feedback-company-modal').value,
            email: form.current.querySelector('input[name="email"]').value,
        }

        const formData = new FormData();
        formData.append('region', data.region);
        formData.append('forSeniorFullStack', data.forSeniorFullStack);
        formData.append('forMiddleBackend', data.forMiddleBackend);
        formData.append('teamSize', data.teamSize);
        formData.append('skill', data.skill);
        formData.append('fullName', data.name);
        formData.append('company', data.company);
        formData.append('email', data.email);
        formData.append('currency', this.state.currency);
        // const file = form.current.querySelector('input[name="file-type"]').files[0];
        // if (file) formData.append('file-246', file);

        fetch(
            'https://panel.stairwaysoft.com/api/contact-form-7/v1/contact-forms/2602/feedback',
            {
                method: 'POST',
                body: formData
            }
        ).then(response => {
            console.log(response, 'response')
            this.setState(
                {
                    ...this.state,
                    showSuccessModal: true
                }
            )
        }).catch(error => {
            console.log(error, 'error');
            this.setState(
                {
                    ...this.state,
                    showErrorMsg: true
                }
            )
        }).finally(() => {
            // reset form
            form.current.reset();
            recaptchaRef.current.reset();
            submitButton.current.removeAttribute('disabled');
            this.setState({
                reCapcheState: false,
                region: null,
                forSeniorFullStack: {
                    min: this.forSeniorFullStack.minDef,
                    max: this.forSeniorFullStack.maxDef
                },
                forMiddleBackend: {min: this.forMiddleBackend.minDef, max: this.forMiddleBackend.maxDef},
                teamSize: {min: this.teamSize.minDef, max: this.teamSize.maxDef},
                showSuccessMsg: true,
                showErrorMsg: false,
                pathFile: null,
                multiRangeReset: !this.state.multiRangeReset,
            })
        });
    }

    render() {
        const state = this.state;

        function onChange(value, e) {
            if (value) {
                state.reCapcheState = true;
                document.querySelector('.re-capcha').classList.remove('error');
            } else {
                state.reCapcheState = false;
                document.querySelector('.re-capcha').classList.add('error');
            }
        }

        return (

            <>
                <Modal visible={this.state.showSuccessModal} effect="fadeInLeft"
                       onClickAway={() => this.setState({showSuccessModal: false})}>
                    <section data-section-modal className={`section-bg ${css.section_bg}`}>

                        <div className={`modal-massage__container ${css.modal_massage__container}`}>
                            <input type={"button"} className='close-modal-btn'
                                   onClick={() => this.setState({showSuccessModal: false})}/>
                            <span className={`modal-massage__title ${css.modal_massage__title}`}>Thank you for your application</span>
                            <span className="modal-massage__sub-title">
                                {/*Our recruiters will contact you soon*/}
                            </span>
                            <button className={`top-nav__btn btn btn--upper modal-massage__btn ${css.top_nav__btn}`}
                                    onClick={() => this.setState({showSuccessModal: false})}>close
                            </button>
                        </div>
                        <ServiceComponent/>
                    </section>
                </Modal>
                <form ref={form}>
                    <h3 className={css.title}>1. Preferred location for the team</h3>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox"
                                   name={'EU_Balkans'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Western Europe (EU & Balkans)
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox"
                                   name={'Ukraine_Moldova_Belarus'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Eastern Europe (Ukraine, Moldova, Belarus)
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox"
                                   name={'Kazakhstan_Azerbaijan'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Central Asia (Kazakhstan, Azerbaijan)
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox"
                                   name={'Israel_UAE'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Middle East (Israel, UAE)
                            </span>
                        </label>
                    </p>
                    <p className={css.errorMessage} ref={regionErrorMessage}>
                        Please select at least one region
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <h3 className={css.title}>2. Attractive budget (hourly rate)</h3>
                    <div className={css.custom_range_container}>
                        <div className={`${css.custom_range_left} ${css.custom_range_left_currency}`}>
                            <div className={css.custom_range_left_currency_label_container}>
                                <label className={css.custom_range_left_currency_label}>
                                    <input
                                        type="radio"
                                        defaultChecked={true}
                                        name={'currency'}
                                        className={css.custom_range_left_currency_input}
                                        onChange={(e) => {
                                            this.setState({
                                                currency: 'USD'
                                            })
                                        }}
                                    />
                                    <span className={css.custom_range_left_currency_input_text}>
                                        <span>
                                            USD
                                        </span>
                                    </span>
                                </label>
                                <label className={css.custom_range_left_currency_label}>
                                    <input
                                        type="radio"
                                        name={'currency'}
                                        className={css.custom_range_left_currency_input}
                                        onChange={(e) => {
                                            this.setState({
                                                currency: 'EUR'
                                            })
                                        }}
                                    />
                                    <span className={css.custom_range_left_currency_input_text}>
                                        <span>
                                            EUR
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className={css.custom_range_container}>

                        <div className={css.custom_range_left}>
                            <p className={css.custom_range_title}>For <strong>Senior Full-Stack </strong></p>
                            <div className={css.custom_range_content}>
                                <MultiRangeSlider
                                    multiRangeResetBool={this.state.multiRangeReset}
                                    min={45}
                                    max={95}
                                    onChange={
                                        (
                                            {
                                                min,
                                                max
                                            }
                                        ) => {
                                            this.forSeniorFullStack = {
                                                min: min,
                                                max: max
                                            }
                                        }}
                                />
                            </div>
                        </div>
                        <div className={css.custom_range_right}>
                            <p className={css.custom_range_title}>For <strong>Middle Backend</strong></p>
                            <div className={css.custom_range_content}>
                                <MultiRangeSlider
                                    multiRangeResetBool={this.state.multiRangeReset}
                                    min={35}
                                    max={65}
                                    onChange={
                                        (
                                            {
                                                min,
                                                max
                                            }
                                        ) => {
                                            this.forMiddleBackend = {
                                                min: min,
                                                max: max
                                            }
                                        }}
                                />

                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <h3 className={css.title}>3. How many people do you need in your remote engineering team?</h3>
                    <div className={css.custom_range_left}>
                        <p className={css.custom_range_title}>Team size:</p>
                        <br/>
                        <br/>
                        <div className={css.custom_range_content}>
                            <MultiRangeSlider
                                multiRangeResetBool={this.state.multiRangeReset}
                                min={1}
                                max={100}
                                onChange={
                                    (
                                        {
                                            min,
                                            max
                                        }
                                    ) => {
                                        this.teamSize = {
                                            min: min,
                                            max: max
                                        }
                                    }}
                            />

                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <h3 className={css.title}>4.Tech stack</h3>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox" name={'PHP'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                PHP
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox" name={'JavaScript'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                JavaScript
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox" name={'Angular'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Angular
                            </span>
                        </label>
                    </p>
                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox" name={'React'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                React
                            </span>
                        </label>
                    </p>

                    <p>
                        <label className={css.custom_checkbox_label}>
                            <input className={css.custom_checkbox_input} type="checkbox" name={'Node JS'}/>
                            <span className={css.custom_checkbox}></span>
                            <span className={css.custom_checkbox_label_text}>
                                Node JS
                            </span>
                        </label>
                    </p>
                    <br/>
                    <div className="feedback-form">
                        <label className="label-modal feedback-form__label feedback-form__label--textarea">
                            <span>Your choose</span>
                            <textarea
                                maxLength={100}
                                id="feedback-message-modal"
                                placeholder="Technology name"
                                name="description"
                                className={`feedback-form__input ${css.feedback_form__input}`}
                            />
                            <p className={css.errorMessage} ref={skillErrorMessage}>
                                Please select at least one skill
                            </p>
                        </label>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <h3 className={css.title}>5.Your contacts</h3>
                    <div className="feedback-form">
                        <label className="label-modal feedback-form__label">
                            <span>Name<span>*</span></span>
                            <input
                                type="text"
                                id="feedback-name-modal"
                                placeholder="Your full name"
                                name="fullname"
                                className="feedback-form__input"
                            />
                            <p className={css.errorMessage} ref={nameErrorMessage}>
                                Fill out the name
                            </p>
                        </label>
                        <label className="label-modal feedback-form__label">
                            <span>Company<span>*</span></span>
                            <input type="text"
                                   id="feedback-company-modal"
                                   placeholder="Company name"
                                   name="company"
                                   className="feedback-form__input"
                            />
                            <p className={css.errorMessage} ref={companyErrorMessage}>
                                Fill out the company
                            </p>
                        </label>
                        <label className="label-modal feedback-form__label">
                            <span> Email<span>*</span></span>
                            <input type="text"
                                   id="feedback-email-modal"
                                   placeholder="Work email adress"
                                   name="email"
                                   className="feedback-form__input"
                            />
                            <p className={css.errorMessage} ref={emailErrorMessage}>
                                Fill out the email
                            </p>
                            <p className={css.errorMessage} ref={emailFormatErrorMessage}>
                                Please enter a valid email address
                            </p>
                        </label>
                    </div>

                    <div className={`feedback-form ${css.feedback_form_file}`}>

                        <div className={`re-capcha ${css.re_capcha}`}>
                            <ReCAPTCHA
                                sitekey="6LemITscAAAAACsaRgO1UkriXPU8B6kqrfYtC9s8"
                                onChange={onChange}
                                type={'image'}
                                hl={'en'}
                                ref={recaptchaRef}
                            />
                        </div>

                        {false && <div className={`attach-file-btn ${css.attach_file_btn}`}>
                            <label htmlFor="file-type"
                                   className={`${css.attach_file_btn} label-modal feedback-form__attach-btn__main-page`}>
                                Attach the file
                            </label>
                            <div className='attach-file-btn__near'>
                                <span className='sub-desc'>(up to 10Mb combined size, only .docx, .pdf file)</span>
                                <div
                                    className={(Boolean(this.state.pathFile)) ? 'type-file-wrapper show' : 'type-file-wrapper'}>
                                    <input type='file'
                                           id='file-type'
                                           name='file-type'
                                           accept=".doc, .docx, .pdf"
                                           onChange={() => {
                                               const file = document.getElementById("file-type");
                                               let value = file.value;
                                               value = value.replace('C:\\fakepath\\', '');
                                               this.setState({
                                                   pathFile: value
                                               })
                                           }}
                                    />
                                    <span className='attach-file-btn__file-name'>{this.state.pathFile}</span>
                                </div>
                            </div>
                            <span className="feedback-form__error-attach" id='attach-error-modal'>Please add file with correct file format</span>
                        </div>}

                        <div className={`${css.form_btn_block} form-btn-block`}>
                            <button ref={submitButton} className={`btn btn--160w btn--upper ${css.btn_upper}`}
                                    type="button"
                                    onClick={this.submitForm}>
                                Send Message
                            </button>
                        </div>

                        <div className="feedback-form__notification">
                            <p
                                className={
                                    this.state.showErrorMsg ? ('feedback-form__error feedback-form__error--show') : ('feedback-form__error')}
                            >
                                Unfortunately, message wasn't sent. Please try to send it again or contact us by phone.
                            </p>
                        </div>
                    </div>
                </form>
                <p className={`${css.text_main_title}`}>
                    Our operating principles
                </p>
                <div className={`${css.text_container}`}>
                    <div className={`${css.text_container_item}`}>
                        <h4 className={`${css.text_container_item_title}`}>
                            Quick Dedicated Team Setup
                        </h4>
                        <div className={`${css.text_container_item_desc}`}>
                            <p>
                                For customers who are faced with the need for rapid scaling of their development team,
                                we provide remote dedicated personnel, that are fully
                                committed to your project.
                            </p>
                            <p>
                                Without spending a lot of precious time searching and hiring your own employees, you can
                                start
                                working with your remote team in a few weeks.
                            </p>
                            <p>
                                Our process is as simple as it can be and consists
                                of only a few steps:
                            </p>
                            <ul>
                                <li>
                                    Getting detailed requirements;
                                </li>
                                <li>
                                    Putting the team together;
                                </li>
                                <li>
                                    Coordinating all developers with the client;
                                </li>
                                <li>
                                    Get to work.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`${css.text_container_item}`}>
                        <h4 className={`${css.text_container_item_title}`}>
                            Reduce Expenses for Recruitment
                        </h4>
                        <div className={`${css.text_container_item_desc}`}>
                            <p>You will save not only financial resources for
                                recruiting and on-boarding staff, but also precious time, having received a
                                ready-to-work team within a few weeks.</p>

                            <p>There is no need to search for people who will
                                participate on the project.
                                <br/>
                                StairwaySoft will provide you with highly qualified specialists in the field of
                                cutting
                                edge technologies.</p>

                            <p>
                                Of course, there are additional savings on
                                workspaces, office expenses, training and introduction to your company’s processes.
                            </p>
                        </div>
                    </div>
                    <div className={`${css.text_container_item}`}>
                        <h4 className={`${css.text_container_item_title}`}>
                            Long-Term Cooperation
                        </h4>
                        <div className={`${css.text_container_item_desc}`}>
                            <p>
                                Instead of managing a large team of developers,
                                you get one point of contact — a project manager who keeps you abreast of all the
                                affairs on
                                the
                                project and provides all the necessary information.
                            </p>

                            <p>
                                While working on a complex project, it is not
                                recommended to change developers.
                                <br/>
                                We are also interested in putting together a team that will be with you for as long as
                                necessary.
                            </p>

                            <p>
                                A team is the most valuable asset so we won’t
                                randomly reassign personnel, but instead we’ll keep them dedicated to your company.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SurveyFormComponent;