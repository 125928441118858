import React, {Component, Fragment} from "react";
import css from './vacancy-list.component.module.scss'
import cssOfFirstInfo from '../first-info/first-info.component.module.scss'
import {Link} from "react-router-dom";

class VacancyListComponent extends Component {
	constructor(prop) {
		super(prop);
		this.state = {
			result: prop.fetch,
			resultValidate: false
		}
	}

	render() {
		if (this.state.result && Object.values(this.state.result).length) {
			this.setState({
				resultValidate: true
			})
		}
    
		return (
			<Fragment>
				{
					this.state.resultValidate &&
					<section className={css.section}>

						<div className={`${css.list_container} container`}>
							{
								this.state.result && this.state.result.map((item, key) =>

									item.title &&

									<div key={key} className={css.list_item}>
										{/*{console.log('====>', item.select_type_to_watching.value, (item.list.length || item.simple_text.split('').length))}*/}
										<h3 className={css.list_item__title}>{item.title}</h3>
										{item.select_type_to_watching.value === 'list' &&
											<ul>
												{item.list && item.list.map((_, key) =>
													<li key={key} className={css.list_item__ul_li}>{_.value}</li>
												)}
											</ul>
										}
										{item.select_type_to_watching && item.select_type_to_watching.value === 'text' &&
											<span className={css.list_item__ul_li}
														dangerouslySetInnerHTML={{__html: item.simple_text}}></span>
										}
									</div>
								)
							}
						</div>

						<div className={`container ${cssOfFirstInfo.short_info__button_container}`}>
							<Link to="/vacancies" className={css.link_as_button__blue}>More jobs</Link> <span
							className={`${cssOfFirstInfo.blue_button} ${cssOfFirstInfo.margin_r}`}
							onClick={this.props.openModal}
						>
              send resume
            </span>
						</div>


					</section>
				}
				{!this.state.resultValidate &&
					<>
						<section className={css.section_empty}>

						</section>
					</>
				}
			</Fragment>
		)
	}
}

export default VacancyListComponent;