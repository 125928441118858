import React, {Component, Fragment} from 'react';
import css from './gallery-slider.module.scss'

export class GallerySliderComponent extends Component {
  constructor(prop) {
    super(prop);
    this.state = {
      gallery: this.props.data,
      gallery_active: '',
      width_gallery: 0,
      window_width: 0,
      animationStatus: '',
      classPosition: '',
      newspaperTiming: {
        duration: Number(this.props.speed) * 1000,
        iterations: 9999999,
      },
      newspaperSpinning: [
        {
          marginLeft: '0',
        },
        {
          marginLeft: '0',
        }
      ],

      imagesLoaded: false

    }
  }

  componentDidMount() {
    document.querySelector('#images').addEventListener('load', (e) => {
      e.target.setAttribute('data-image', true);
      this.windowLoad();
    }, true);
    window.addEventListener('resize', () => {
      this.windowResize();
    }, true);
  }

  componentWillUnmount() {
    document.querySelector('#images').removeEventListener('load', () => {
      this.windowLoad();
    }, true);
    window.removeEventListener('resize', () => {
      this.windowResize();
    }, true);
  }

  windowLoad() {
    const notLoaded = document.querySelector('[data-image=false]');
    if (!notLoaded) {
      const gallery = document.querySelector(`#images`);
      console.log(gallery)
      this.setState({
        newspaperSpinning: [
          {
            marginLeft: '0',
          },
          {
            marginLeft: `-${gallery && gallery.getBoundingClientRect().width}px`,
          }
        ]

      })
      this.setGalleryWidth();
      gallery && gallery.animate(this.state.newspaperSpinning, this.state.newspaperTiming).play();
    }
  }

  windowResize = () => {
//    console.log('resize')
    const gallery = document.querySelector(`#images`);
    if (this.state.window_width !== window.innerWidth) {

      this.setState({window_width: window.innerWidth});
      if (gallery) {
        gallery.style.width = `unset`;

        this.setGalleryWidth();
        gallery.animate(this.state.newspaperSpinning, this.state.newspaperTiming);
      }
    }

  }

  setGalleryWidth = () => {
    setTimeout(() => {

      const gallery = document.querySelector(`#images`);

      this.setState({width_gallery: gallery.getBoundingClientRect().width});

      gallery.style.width = `${this.state.width_gallery}px`;
//      gallery.style.marginLeft = `-${this.state.width_gallery - 400}px`;

    }, 300);

  }

  imageCoverOrContain(_) {
    if (_.image_fill === 'Contain') return css.fit_contain;
    if (_.image_fill === 'Cover') return css.fit_cover;
  }

  render() {

    const galleryArray = this.state.gallery;
    return (
      <Fragment>
        <section className={css.section}>
          <div id='images'
               className={`${css.gallery}`}
          >
            <div className={`${css.top}`}>
              {
                Object.keys(galleryArray).length && galleryArray.map((_, index) => {
                  if ((index + 1) % 2 !== 0) {

                    return (
                      <img
                        data-image={false}
                        style={_.width - 0 ? {width: _.width + 'px'} : undefined}
                        className={`${this.imageCoverOrContain(_)}  ${css.img}`}
                        key={index}
                        src={_.image}
                        alt=""
                      />
                    )
                  }

                })
              }
            </div>
            <div className={`${css.down}`}>
              {
                Object.keys(galleryArray).length && galleryArray.map((_, index) => {
                  if ((index + 1) % 2 === 0) {
                    return (
                      <img
                        data-image={false}
                        style={_.width - 0 ? {width: _.width + 'px'} : undefined}
                        className={`${this.imageCoverOrContain(_)} ${css.img}`}
                        key={index}
                        src={_.image}
                        alt=""
                      />
                    )
                  }
                })
              }
            </div>
          </div>

          <div className={`${css.gallery_banner}`}>
            <div className={`${css.top}`}>
              {
                Object.keys(galleryArray).length && galleryArray.map((_, index) => {
                  if ((index + 1) % 2 !== 0) {
                    return (
                      <img
                        data-image={true}
                        style={_.width - 0 ? {width: _.width + 'px'} : undefined}
                        className={`${this.imageCoverOrContain(_)} ${css.img_banner}`}
                        key={index}
                        src={_.image}
                        alt=""
                      />
                    )
                  }

                })
              }
            </div>
            <div className={`${css.down}`}>
              {
                Object.keys(galleryArray).length && galleryArray.map((_, index) => {
                  if ((index + 1) % 2 === 0) {

                    return (
                      <img
                        data-image={true}
                        style={_.width - 0 ? {width: _.width + 'px'} : undefined}
                        className={`${this.imageCoverOrContain(_)} ${css.img_banner}`}
                        key={index}
                        src={_.image}
                        alt=""
                      />
                    )
                  }

                })
              }
            </div>
          </div>
        </section>
      </Fragment>
    )
  }

}