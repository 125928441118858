import React, {Component, Fragment} from 'react';
import HeaderComponent from '../../reuse/header/header.component';
import FooterComponent from '../../reuse/footer/footer.component';
import LoaderComponent from "../../reuse/loader/Loader.component";
import MainPageFormComponent from "../mainPage/form/main-page-form.component";
import ConnectComponent from "../../reuse/connect/connect.component";
import AboutHomeComponent from "../mainPage/about/about.component";
import FeaturedJobsComponent from "../../reuse/vacancies/featured-jobs/featured-jobs.component";
import {FirstScreenComponent} from "./first-screen/first-screen.component";
import {SecondScreenComponent} from "./second-screen/second-screen.component";
import {GallerySliderComponent} from "./gallery-slider/gallery-slider.component";
import css from "./career.module.scss";
import FeedbackComponent from "./feedback/feedback.component";
import JoinUsComponent from "./join-us/join-us.component";
import PeopleSliderComponent from "./people-slider/people-slider.component";

class CareerComponent extends Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

        this.state = {
            showLoader: true,
            list: {},
            gallery: [],
        };


        // document.title = 'Stairwaysoft - Career';

    }

    getDataFromAPI = () => {
        fetch('https://panel.stairwaysoft.com/api/wp/v2/career')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    showLoader: false,
                    list: data.list,
                    gallery: data.career_gallery.img,
                    speed: data.career_gallery.speed,
                    feedback_message: data.feedback_message,
                    feedback_rating: data.feedback_rating,
                    feedback_rating_title: data.feedback_rating_title,
                    feedback_rating_all_smiles: data.feedback_rating_all_smiles,
                    first_screen_image: data.first_screen,
                    career_first_screen_video: data.career_first_screen_video
                })
            })
            .catch(error => {
                console.error(error)
            })
    }

    componentDidMount() {
        this.getDataFromAPI();
        setTimeout(() => {
            this.setState({
                showLoader: false,
            })
        }, 100)
    }

    render() {

        return (
            <Fragment>
                <LoaderComponent visible={this.state.showLoader}/>
                <HeaderComponent/>
                {(this.state.first_screen_image && this.state.career_first_screen_video) && <FirstScreenComponent
                    first_screen_image={this.state.first_screen_image}
                    career_first_screen_video={this.state.career_first_screen_video}/>}
                {
                    this.state.list && Object.values(this.state.list).length &&
                    <SecondScreenComponent data={this.state.list}/>
                }

                {
                    this.state.gallery && Object.values(this.state.gallery).length &&
                    <GallerySliderComponent data={this.state.gallery} speed={this.state.speed}/>
                }

                {this.state.feedback_rating &&
                    <FeedbackComponent
                        data={this.state.feedback_rating}
                        title={this.state.feedback_rating_title}
                        feedback_rating_all_smiles={this.state.feedback_rating_all_smiles}
                    />}

                <JoinUsComponent/>

                {
                    this.state.feedback_message &&
                    <PeopleSliderComponent data={this.state.feedback_message}/>
                }
                <section className='job'>
                    <div className="container">
                        <h2 className={`${css.job_title}`}>Featured jobs</h2>
                    </div>
                    <FeaturedJobsComponent limit={4} filterView={false}/>
                </section>

                {/*<FeaturedJobsComponent limit={4} filterView={false}/>*/}
                <AboutHomeComponent updateData={this.props.updateData}/>
                <ConnectComponent/>
                <MainPageFormComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default CareerComponent;
