import React, {Component, Fragment} from "react";
import css from './blog-item.module.scss';
import {Link} from "react-router-dom";

class BlogItemComponent extends Component {
    reg = /[\r\n]+/g;

    constructor(props) {
        super(props);

        this.state = {
            blog: [{
                thumbnail_url: undefined,
                title: undefined,
                description: undefined,
                post_name: undefined
            }]
        }
    }

    componentDidMount() {
        this.setState({
            blog: this.props.blog
        })
    }

    render() {
        console.log(this.state.blog, 'qdqw')
        return (
            <Fragment>
                <div className={css.blogs}>
                    {
                        this.state.blog.map((item, index) => {
                            const validateFields = item.title && item.thumbnail_url && item.description;
                            if (index === 0 && validateFields) {
                                return (
                                    <div key={index} className={`${css.first_post}`}>
                                        <img src={item.thumbnail_url} alt="" className={`${css.first_img}`}/>
                                        <div className={`${css.first_post_description}`}>
                                            <h4
                                                className={`${css.first_post_title}`}>{item.title.replace(/[\r\n]+/g, '\n').length > 103 ? item.title.slice(0, 103) + '...' : item.title}</h4>
                                            <p
                                                className={`${css.first_post_text}`}>{item.description.length > 181 ? item.description.slice(0, 181) + '...' : item.description}</p>
                                            <Link className={`${css.first_post_link}`}
                                                  to={`/blog/article/${item.post_name}`}>read
                                                more</Link>
                                        </div>
                                    </div>
                                )
                            }
                            if (index > 0 && validateFields) {
                                return (
                                    <div key={index} className={`${css.blogs_item_wrapper}`}>
                                        <div className={`${css.blogs_item}`}>
                                            <img src={item.thumbnail_url} alt="" className={`${css.blogs_img}`}/>
                                            <div className={`${css.blogs_border}`}>
                                                <div className={`${css.blogs_description}`}>
                                                    <h5 className={`${css.blogs_title}`}>
                                                        {item.title.length > 103 ? item.title.slice(0, 103) + '...' : item.title}
                                                    </h5>
                                                    <p className={`${css.blogs_text}`}>
                                                        {item.description.length > 181 ? item.title.slice(0, 181) + '...' : item.title}
                                                    </p>

                                                    <div className={css.blogs_link}>
                                                        <Link className={`${css.blogs_link_a}`}
                                                              to={`/blog/article/${item.post_name}`}>read more</Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </Fragment>
        )
    }
}

export default BlogItemComponent;