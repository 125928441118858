import React, {Component, Fragment} from 'react';
import './join-us.component.scss';
import CvFormModalComponent from "../../vacancy/cv-form-modal/cv-form-modal.component";
import Modal from "react-awesome-modal";

class JoinUsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false
		}
	}

	openModal = () => {
		this.setState({
			modal: true
		})
	}

	closeModal = () => {
		this.setState({
			modal: false
		})
	}

	modalThankYouIsVisible = () => {
		if (this.state.modal) this.setState({modal: false});
		this.setState({modalThankYouVisible: true});
	}

	render() {
		return (
			<Fragment>

				{this.state.modalThankYouVisible &&
					<Modal visible={this.state.modalThankYouVisible} effect="fadeInLeft"
								 onClickAway={() => this.setState({modalThankYouVisible: false})}>
						<section data-section-modal className="section-bg scroll">
							<input type={"button"} className='close-modal-btn'
										 onClick={() => this.setState({modalThankYouVisible: false})}/>
							<div className="modal-massage__container">
								<span className="modal-massage__title">Thank you for your application</span>
								<span className="modal-massage__sub-title">Our recruiters will contact you soon</span>
								<button className="top-nav__btn btn btn--upper modal-massage__btn"
												onClick={() => this.setState({modalThankYouVisible: false})}>close
								</button>
							</div>
						</section>
					</Modal>
				}

				{this.state.modal &&
					<CvFormModalComponent modalThankYouIsVisible={this.modalThankYouIsVisible} widthVacanciesList={true}
																closeModal={this.closeModal}/>}
				<section className='join-us'>
					<div className="container">
						<div className="join-us_wrapper">
							<img className="join-us_logo" src="/img/header/sws_logo_w.svg"/>
							<p className='join-us_text'>
								We are not perfect but we always listen to you to make StairwaySoft
								better!
							</p>
							<input type="button" className='join-us_link bottom_fonts' value='join us' onClick={this.openModal}/>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}

export default JoinUsComponent;
