import React, {Component} from 'react';
import LoaderComponent from "../../reuse/loader/Loader.component";
import HeaderComponent from "../../reuse/header/header.component";
import FooterComponent from "../../reuse/footer/footer.component";
import css from './survey.module.scss';
import SurveyFormComponent from "./surveyForm/surveyForm.component";

class SurveyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        };
    }

    componentDidMount() {
        this.setState({
            showLoader: false
        });
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <LoaderComponent visible={this.state.showLoader}/>
                <HeaderComponent disableModalGetInTouchProps={true}/>

                <section className={css.top_img_section__survey + ' top-img-section'}>
                    <div className="top-img-section__container">
                        <div className="top-img-section__content-wrapper">
                            <h2 className="section-title section-title--large section-title--left top-img-section__title">
                                {/*dangerouslySetInnerHTML={{__html: title}}>*/}
                                {/*todo from api*/}
                                Perfect dedicated engineering team
                            </h2>
                            <div className="top-img-section__text-wrapper">
                                {/*dangerouslySetInnerHTML={{__html: short_description}}>*/}
                                {/*todo from api*/}
                                Answer a few simple questions about your expectations about your own perfect dedicated
                                engeneering team
                            </div>
                        </div>
                    </div>
                </section>

                <div className={css.container + ' container'}>
                    <h1 className={css.title}>Your perfect dedicaded engineering team is:</h1>
                    {!this.state.showLoader && <SurveyFormComponent/>}
                </div>


                <FooterComponent/>
            </>
        )
    }
}

export default SurveyComponent;