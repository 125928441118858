import React, {Component, Fragment} from 'react';
import css from './second-screen.module.scss';

export class SecondScreenComponent extends Component {
	constructor(prop) {
		super(prop);
		this.state = {
			left: this.props.data.left,
			right: this.props.data.right,
			activated: false,
		}

	}

	items({_, index, classActive, classesName}) {

		if (!classActive) {
			return (
				<li key={index}
						className={`list_plus ${classesName} ${classesName && classesName.join(' ')}`}>
					{_.text}
				</li>
			);

		} else {
			return (
				<li key={index}
						className={`list_plus ${classesName} ${classesName && classesName.join(' ')}`}>
					{_.text}
				</li>
			);
		}
	}

	setAnimation = () => {
		const wrapper = document.querySelector(`.${css.title}`).getBoundingClientRect();
		if (wrapper && wrapper.top < 400 && wrapper.bottom > 0 && !this.state.activated) {
			const list = document.querySelectorAll(`.${css.ul}`)
			for (let item of list) {
				for (let liIndex in item.querySelectorAll('li')) {
					if (!isNaN(Number(liIndex))) {
						setTimeout(() => {
							item.querySelectorAll('li')[liIndex].classList.add(css.active_li);

						}, (liIndex * 400) - (liIndex * 100))
					}

				}
			}
			this.setState({activated: true})
		}
	}

	componentDidMount() {
		window.addEventListener('scroll', this.setAnimation);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.setAnimation);
	}

	render() {

		return (
			<Fragment>
				<section>
					<div className={`container ${css.w100}`}>
						<h3 className={css.title}>
							Our values are focused on providing
							comfortable work and comprehensive support
							not only in work processes
						</h3>

						<div className={css.list_container}>
							<ul className={css.ul}>
								{this.state.left && this.state.left.map((_, index) => {
										if (index === 0) {
											return this.items({_, index, classActive: css.active_li, classesName: [css.li]});
										}
										if (index !== 0) {
											return this.items({_, index, classActive: false, classesName: [css.li, css.li_left]});
										}
									}
								)}
							</ul>
							<ul className={css.ul}>
								{
									this.state.right && this.state.right.map((_, index) => {
											if (index === 0) {
												return this.items(
													{_, index, classActive: css.active_li, classesName: [css.li]}
												);
											}
											if (index !== 0) {
												return this.items({_, index, classActive: false, classesName: [css.li, css.li_right]});
											}
										}
									)}
							</ul>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}

